import React from 'react'

import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import WarningIcon from 'components/common/WarningIcon'

import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material'

import { success, error, odrNonCompliant } from 'config/themeConfig'

export enum DefectRate {
  COMPLIANT = 0,
  NON_COMPLIANT = 5,
  PROBATION = 7,
}

export type Props = {
  defectRate: number
}

const StyledRowDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const StyledLeftDiv = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const StyledLeftTypography = styled(Typography)<{ component: string }>(
  ({ theme }) => ({
    marginLeft: theme.spacing(2),
  }),
)

type StyledContainerDivProps = {
  defectRate: number
}

const StyledContainerDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'defectRate',
})<StyledContainerDivProps>(
  (props) => ({
    color:
      props.defectRate < DefectRate.NON_COMPLIANT
        ? success.main
        : props.defectRate >= DefectRate.NON_COMPLIANT &&
            props.defectRate < DefectRate.PROBATION
          ? odrNonCompliant.main
          : props.defectRate >= DefectRate.PROBATION
            ? error.main
            : undefined,
  }),
  ({ theme }) => ({
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  }),
)

export const OverallDefectRateLabel: React.FC<{ defectRate: number }> = ({
  defectRate,
}) => {
  return (
    <StyledContainerDiv defectRate={defectRate}>
      <Typography variant="h2" component="div">
        {defectRate}%
      </Typography>
      <StyledLeftDiv>
        {defectRate < DefectRate.NON_COMPLIANT && (
          <StyledRowDiv>
            <CheckCircleOutlined />{' '}
            <StyledLeftTypography variant="h4" component="span">
              Compliant
            </StyledLeftTypography>
          </StyledRowDiv>
        )}
        {defectRate >= DefectRate.NON_COMPLIANT &&
          defectRate < DefectRate.PROBATION && (
            <StyledRowDiv>
              <CancelOutlined />{' '}
              <StyledLeftTypography variant="h4" component="span">
                Non-compliant
              </StyledLeftTypography>
            </StyledRowDiv>
          )}
        {defectRate >= DefectRate.PROBATION && (
          <StyledRowDiv>
            <WarningIcon>
              <Typography>Probation</Typography>
            </WarningIcon>
          </StyledRowDiv>
        )}
      </StyledLeftDiv>
    </StyledContainerDiv>
  )
}

export default OverallDefectRateLabel
