import { useState, CSSProperties } from 'react'
import AsyncSelect from 'react-select/async'

import { getSellerSearch } from 'v2/services'

interface Option {
  label: string
  value: string
  id: string
  vmmId: number
}

const useAsyncSellerSelect = () => {
  const [selectedOptions, setSelectedOptions] = useState<Option | null>(null)

  const fetchOptions = async (inputValue: string): Promise<Option[]> => {
    if (!inputValue) {
      return []
    }

    const data = await getSellerSearch({
      q: inputValue,
    })

    return data
      ?.filter(({ display_name }) => display_name)
      .map(({ id, display_name, vmm_id }: any) => ({
        id,
        vmmId: vmm_id,
        value: id,
        label: display_name,
      }))
  }

  const handleInputChange = (newValue: string): string => {
    const value = newValue.replace(/\W/g, '') // remove any non-alphanumeric characters from the string
    return value
  }

  const handleChange = (newValue: any) => {
    setSelectedOptions(newValue)
  }

  const renderComponent = ({
    placeholder = 'Select an option...',
  }: {
    placeholder?: string
  }) => (
    <AsyncSelect
      cacheOptions
      loadOptions={fetchOptions}
      onChange={handleChange}
      value={selectedOptions}
      onInputChange={handleInputChange}
      placeholder={placeholder}
      isClearable
      styles={{
        control: (baseStyles: CSSProperties) => ({
          ...baseStyles,
          lineHeight: 1,
          borderRadius: 8,
          height: 35,
          minHeight: 35,
          fontSize: 'var(--canvas-font-size--sm)',
          borderColor: 'var(--canvas-border-color--input)',
        }),
        indicatorSeparator: (baseStyles: CSSProperties) => ({
          ...baseStyles,
          display: 'none',
        }),
        clearIndicator: (baseStyles: CSSProperties) => ({
          ...baseStyles,
          color: 'var(--canvas-icon-color--default)',
        }),
        dropdownIndicator: (baseStyles: CSSProperties) => ({
          ...baseStyles,
          color: 'var(--canvas-icon-color--default)',
        }),
      }}
    />
  )

  return { renderComponent, selectedOptions }
}

export default useAsyncSellerSelect
