import {
  Modal,
  Grid,
  Button,
  Placeholder,
} from '@enterprise-ui/canvas-ui-react'
import { useQuery } from '@tanstack/react-query'
import capitalize from 'lodash/fp/capitalize'
import { getItemTypeTaxonomies } from 'v2/services/getItemTypeTaxonomies'

import { AllowedProductSubType } from 'v2/types/promotions'

interface ItemTypeModalProps {
  isVisibile: boolean
  selectedProductSubtype?: AllowedProductSubType
  onRequestClose: () => void
}

const ItemTypeModal = ({
  isVisibile,
  selectedProductSubtype,
  onRequestClose,
}: ItemTypeModalProps) => {
  const { data: itemTypes, isLoading } = useQuery(
    ['GET_ITEM_TYPE_TAXNOMIES', selectedProductSubtype?.sub_type_id],
    () =>
      getItemTypeTaxonomies({
        parentId: selectedProductSubtype?.sub_type_id ?? '',
        depth: 3,
        perPage: 200,
        type: 'item_type',
      }).then((response) =>
        response.filter((res) =>
          selectedProductSubtype?.allowed_item_types_ids?.includes(res.id),
        ),
      ),
    {
      enabled: !!selectedProductSubtype?.sub_type_id,
    },
  )

  return (
    <>
      <Modal
        isVisible={isVisibile}
        headingText={capitalize(selectedProductSubtype?.sub_type_name ?? '')}
        onRefuse={onRequestClose}
      >
        <div className="hc-pa-normal">
          {isLoading ? (
            <>
              <Placeholder.Rect height="100px" />
            </>
          ) : (
            <>
              <p className="hc-mb-normal">
                Below is the list of item types allowed to participate in the
                promotion:
              </p>
              <Grid.Container className="hc-pa-normal">
                {itemTypes?.map((itemType) => (
                  <Grid.Item className="hc-pa-none" xs={6} sm={6} md={6} lg={6}>
                    <li className="hc-clr-grey01">{itemType.label}</li>
                  </Grid.Item>
                ))}
              </Grid.Container>
            </>
          )}

          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button onClick={onRequestClose} type="secondary">
                Close
              </Button>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </>
  )
}

export default ItemTypeModal
