import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { OrderStatus } from 'types/Orders'

import { getDateSubtractedByMonths } from 'v2/utils/date'
import { buildURLQueryParams } from 'v2/utils/url'

/**
 * The API gets the order status count for last 18 months
 */
export const getOrderStatusCounts = async ({
  orderStatus,
}: {
  orderStatus: OrderStatus[]
}) => {
  const currentDate = new Date()
  const startDate = getDateSubtractedByMonths(currentDate, 18)

  currentDate.setHours(0, 0, 0, 0)
  startDate.setHours(0, 0, 0, 0)

  const formattedDate = `${startDate.toISOString()}/${currentDate.toISOString()}`
  const params = buildURLQueryParams({
    order_status: `${orderStatus}`,
    requested_shipment_date: formattedDate,
    page: 1,
    per_page: 5,
  })
  const url = `${apiConfig.sellerOrders}/order_status_counts?${params}`

  const response = await axios.get(url)
  return response?.data
}
