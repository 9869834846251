import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { format } from 'date-fns'
import { useQuery } from '@tanstack/react-query'
import { datePattern } from 'v2/constant/date'
import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'
import { getOrdersToBeShipped } from 'v2/services/getOrdersToBeShipped'
import { useOrdersDashboardStore } from 'v2/store'
import { transformToGraphAxes } from 'v2/utils/helper'
import { StyledCardContainer } from '../styles'

const OrdersToBeShippedGraph = () => {
  const { sellerId } = useOrdersDashboardStore()

  const getData = async () => {
    const response = await getOrdersToBeShipped({ sellerId })

    const data = response.map((ele: any) => {
      const date = format(
        new Date(ele?.requested_shipment_date),
        datePattern.slash.MM_dd_yyyy,
      )
      return {
        ...ele,
        requested_shipment_date: date,
      }
    })

    const formattedData = transformToGraphAxes({
      response: data,
      xValue: 'requested_shipment_date',
      yValue: 'count',
    })

    return formattedData
  }

  const {
    data: graphData,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['ORDERS_TO_BE_SHIPPED', sellerId],
    queryFn: getData,
  })

  if (isFetching) {
    return (
      <Placeholder className="hc-pv-xl">
        <Grid.Container justify="center" align="center">
          <Grid.Item xs={12}>
            <Placeholder.Rect emphasized height="270px" />
          </Grid.Item>
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <p className="hc-pa-expanded hc-ta-center">
        There was an error fetching the data
      </p>
    )
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={graphData}
          margin={{ top: 40, right: 30, left: -50, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3, 3" />
          <XAxis className="hc-fs-min" dataKey="x_axis" dy={14} />
          <YAxis
            className="hc-fs-min"
            dataKey="y_axis"
            tick={{ fontSize: 11 }}
            width={140}
          />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            labelFormatter={() => ''}
            formatter={(value: any) => [`${value}`]}
          />
          <Bar
            dataKey="y_axis"
            fill="var(--canvas-font-color--interactive-contrast)"
            barSize={20}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>

      <Grid.Container className="hc-mv-normal" align="center">
        <StyledCardContainer className="hc-ml-2x hc-pa-dense" />
        <Grid.Item>
          <p className="hc-fs-min hc-txt-capitalize">Count of orders</p>
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default OrdersToBeShippedGraph
