import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup'

import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import styled from '@emotion/styled'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import Input from 'components/common/Input'
import RadioGroup from 'components/common/RadioGroup/RadioGroup'

import { getTelephoneCodeByCountry } from 'services/geographicalCodes'
import {
  maxCharacterCount,
  validationHandler,
  ValidationSchema,
} from 'services/validation'
import formatPhoneNumber from 'services/formatPhoneNumber'
import { getCountryFlag } from 'services/getCountryFlag'

import { currentSeller, isSellersPending } from 'store/selectors'
import { editSeller } from 'store/seller/actionCreators'

import { Validation } from 'types/Validation'
import { CountryNameCode } from 'types/Country'

const StyledSpacer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3.85, 6, 3.85, 6),
}))

const EMAIL_LABEL = 'Enter Email Address'

enum RadioOptions {
  YES = 'Yes',
  NO = 'No',
}

const validationSchema: ValidationSchema = yup.object().shape({
  email: yup.string().label(EMAIL_LABEL).email().required(),
  hasPhone: yup.string().required(),
  phoneNumber: yup.string().when(['hasPhone'], {
    is: (hasPhone: string) => hasPhone === RadioOptions.YES,
    then: yup
      .string()
      .required()
      .label('Phone Number')
      .length(14, 'Phone number is not a valid phone number'),
  }),
  extension: yup.string().when(['hasPhone'], {
    is: (hasPhone: string) => hasPhone === RadioOptions.YES,
    then: yup
      .string()
      .label('Extension')
      .matches(/^\d+$/, {
        message: 'Extension must contain numbers only',
        excludeEmptyString: true,
      })
      .test({
        name: 'isExtension',
        test: maxCharacterCount('extension', 5),
      }),
  }),
})

interface Props {
  isOpen: boolean
}

const EditGuestContact = ({ isOpen }: Props) => {
  const seller = useSelector(currentSeller)
  const isPending = useSelector(isSellersPending)

  const dispatch = useDispatch()

  const [validation, setValidation] = useState<Validation>({})
  const [email, setEmail] = useState(seller?.guest_services_email ?? '')
  const [hasPhone, setHasPhone] = useState(
    seller?.has_guest_services_phone_number === undefined
      ? ''
      : seller?.has_guest_services_phone_number
        ? RadioOptions.YES
        : RadioOptions.NO,
  )
  const [phoneNumber, setPhoneNumber] = useState(
    seller?.guest_services_phone_number?.number ?? '',
  )

  const [extension, setExtension] = useState(
    seller?.guest_services_phone_number?.extension_number ?? '',
  )

  const phoneCountry = CountryNameCode.UNITED_STATES

  const handleEmailChange = (value: string) => {
    setEmail(value)
  }

  const handleHasPhoneChange = (value: string) => {
    setHasPhone(value)
  }

  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(formatPhoneNumber(value))
  }

  const handleExtensionChange = (value: string) => {
    setExtension(value)
  }

  const startAdornment = (
    <Box sx={{ whiteSpace: 'nowrap', mr: 1 }}>
      {getCountryFlag(phoneCountry)} +1
    </Box>
  )

  const handleSubmit = () => {
    const { validation: formValidation, isValid } = validationHandler(
      validationSchema,
      { email, hasPhone, phoneNumber, extension },
    )
    setValidation(formValidation)

    if (isValid && seller) {
      dispatch(
        editSeller({
          ...seller,
          guest_services_email: email,
          has_guest_services_phone_number: hasPhone === RadioOptions.YES,
          guest_services_phone_number:
            hasPhone === RadioOptions.YES
              ? {
                  country_code: getTelephoneCodeByCountry(phoneCountry),
                  number: phoneNumber,
                  extension_number: extension,
                }
              : undefined,
        }),
      )
    }
  }

  return (
    <DialogContainer
      title={
        seller?.guest_services_email
          ? 'Edit Contact Info for Guest Inquiry'
          : 'Add Contact Info for Guest Inquiry'
      }
      isOpen={isOpen}
      isPending={isPending}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            isRequired
            id="email"
            name="email"
            label={EMAIL_LABEL}
            value={email}
            onChange={handleEmailChange}
            isDisabled={isPending}
            validation={validation}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            id="phone"
            name="hasPhone"
            label="Do you have a United States phone number for guest inquiry?"
            value={hasPhone}
            onChange={handleHasPhoneChange}
            options={[
              { label: RadioOptions.YES, value: RadioOptions.YES },
              { label: RadioOptions.NO, value: RadioOptions.NO },
            ]}
          />
        </Grid>
        {hasPhone !== RadioOptions.YES && <StyledSpacer />}
        {hasPhone === RadioOptions.YES && (
          <Grid container>
            <Grid item xs={6} sx={{ p: 2 }}>
              <Input
                isRequired
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                isDisabled={isPending}
                validation={validation}
                startAdornment={startAdornment}
              />
            </Grid>
            <Grid item xs={6} sx={{ pt: 2 }}>
              <Input
                id="extension"
                name="extension"
                label="Extension (optional)"
                value={extension}
                onChange={handleExtensionChange}
                isDisabled={isPending}
                validation={validation}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </DialogContainer>
  )
}

export default EditGuestContact
