import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'
import { useQuery } from '@tanstack/react-query'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { getUnshippedOrdersAge } from 'v2/services/getUnshippedOrdersAge'
import { useOrdersDashboardStore } from 'v2/store'
import { getCurrentDateWithStartAndEndTime } from 'v2/utils/date'

const COLORS = ['#BC497B', '#3CB9B5', '#2758B9']

const RADIAN = Math.PI / 180

const UnshippedPastDueGraphByAge = () => {
  const { sellerId } = useOrdersDashboardStore()

  const getData = async () => {
    const response = await getUnshippedOrdersAge({
      date: getCurrentDateWithStartAndEndTime(),
      sellerId,
    })
    const data = [
      ...(response?.uptoOneDay > 0
        ? [
            {
              name: 'upto 1 Day',
              value: response?.uptoOneDay,
            },
          ]
        : []),
      ...(response?.moreThanTwoDays > 0
        ? [
            {
              name: '2 to 7 Days',
              value: response?.moreThanTwoDays,
            },
          ]
        : []),
      ...(response?.ordersAtRisk
        ? [
            {
              name: '8 Days or More',
              value: response?.ordersAtRisk,
            },
          ]
        : []),
    ]
    return data
  }

  const { data, isFetching, isError } = useQuery({
    queryKey: ['UNSHIPPED_PAST_DUE_BY_AGE_GRAPH', sellerId],
    queryFn: getData,
  })

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5 + 100
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        <tspan x={x} dy="0" fontSize={14}>
          {`${data?.[index].value > 0 ? data?.[index].name : ''}`}
        </tspan>
        <tspan
          x={x}
          dy="1em"
          fontSize={20}
          fill="var(--canvas-background-color--interactive)"
        >
          {`${data?.[index].value > 0 ? data?.[index].value : ''}`}
        </tspan>
      </text>
    )
  }

  if (isFetching) {
    return (
      <Placeholder className="hc-pv-xl">
        <Grid.Container justify="center" align="center">
          <Grid.Item xs={12}>
            <Placeholder.Rect emphasized height="270px" />
          </Grid.Item>
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <p className="hc-pa-expanded hc-ta-center">
        There was an error fetching the data.
      </p>
    )
  }

  return (
    <ResponsiveContainer width={'100%'} height={350}>
      <PieChart width={500} height={350}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          label={renderCustomizedLabel}
        >
          {data?.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

export default UnshippedPastDueGraphByAge
