import React from 'react'
import { JSX } from 'react/jsx-runtime'
import { useDispatch } from 'react-redux'
import capitalize from 'lodash/fp/capitalize'
import kebabCase from 'lodash/fp/kebabCase'

import styled from '@emotion/styled'
import { error, grey, white } from 'config/themeConfig'

import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Tooltip from '@mui/material/Tooltip'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'

import Text from 'components/common/Text'

import { RoutePath, sellerNavPaths } from 'services/NavigationHelper'

import { openDialog } from 'store/dialog/actionCreator'
import { setCurrentSeller } from 'store/seller/actionCreators'

import { SmsSeller, SellerStatus } from 'types/Seller'

import { NavDivider, ExpandedNavDivider } from './NavDivider'
import { getExternalNav, getInternalNav, NavConfig } from './sideNavConfig'
import SideNavItem, { Props as SideNavItemProps } from './SideNavItem'

import { SideNav as CanvasSideNav } from '@enterprise-ui/canvas-ui-react'
import Typography from '@mui/material/Typography'

const StyledSideNavHeader = styled(CanvasSideNav.Header)(({ theme }) => ({
  display: 'flex',
  backgroundColor: `${theme.palette.grey[900]} !important`,
  justifyContent: 'flex-end !important',
  '& svg': {
    fill: theme.palette.grey[400],
  },
  '& :hover>svg': {
    fill: theme.palette.grey[900],
  },
}))

const StyledSideNavContainer = styled(CanvasSideNav.Navigation)({
  background: `${grey[900]} !important`,
  '& svg': {
    color: `${grey[300]} !important`,
  },
}) as typeof List

const StyledSideNavListItem = styled(CanvasSideNav.NavigationItem, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<StyledProps>((props) => ({
  padding: props.theme.spacing(0.5, 0.5, 0.5, 2),
  margin: props.theme.spacing(0.25, 0.5),
  paddingLeft: '8px !important',
  '&:hover': {
    color: grey[300],
    backgroundColor: '#00000080 !important',
    textDecoration: 'none',
    borderRadius: '4px',
  },
  borderRadius: props.isActive ? 4 : undefined,
  '& span': {
    width: '20px',
    height: '20px',
  },
  color: `${white.main} !important`,

  '& button': {
    fill: `${white.main} !important`,
    color: `${white.main} !important`,
  },
}))

type StyledProps = {
  isActive?: boolean
  iconRotateLeft?: boolean
}

const StyledText = styled(Text)({
  color: error.light,
})

interface Props {
  isExternalUser: boolean
  selectedItem: RoutePath
  handleClose: () => void
  open: boolean
  seller: SmsSeller | undefined
  memberOf: string[]
  setShowSideNav: (value: boolean) => void
}

export const SideNav = ({
  handleClose,
  selectedItem,
  isExternalUser,
  open,
  seller,
  memberOf,
  setShowSideNav,
}: Props) => {
  const showSellerNav = isExternalUser || sellerNavPaths.includes(selectedItem)
  const showInternalNav = !showSellerNav && !isExternalUser

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (showInternalNav && seller) {
      dispatch(setCurrentSeller(null))
    }
  }, [showInternalNav, seller, dispatch])

  const generateExternalNav = () => {
    const openDialogCallback = (params: any) => dispatch(openDialog(params))
    return generateSideNav(
      getExternalNav({
        memberOf,
        sellerId: seller?.id,
        sellerSource: seller?.source,
        sellerStatus: seller?.status,
        openDialog: openDialogCallback,
      }),
    )
  }

  const generateInternalNav = () => {
    return generateSideNav(getInternalNav(memberOf))
  }

  const generateSideNav = (config: NavConfig[]) => {
    let navItems: JSX.Element[] = []

    config.forEach((item, i, items) => {
      let content
      const props: SideNavItemProps = {
        ...item,
        selectedItem,
        closeSideNav: handleClose,
      }

      if (item.navGroup && item.subRoutes) {
        content = (
          <CanvasSideNav.NavigationGroup key={`${i}-${item.text}`}>
            <StyledSideNavListItem key={item.text}>
              <Typography className="hc-clr-grey04 hc-fs-md">
                {item.text.toUpperCase()}
              </Typography>
            </StyledSideNavListItem>
            <CanvasSideNav.NavigationGroupContent>
              {item.subRoutes.map((subRoute: any, i) => {
                return (
                  <SideNavItem
                    sellerId={subRoute.unsetSellerId ? undefined : seller?.id}
                    key={i}
                    selectedItem={selectedItem}
                    {...subRoute}
                  />
                )
              })}
            </CanvasSideNav.NavigationGroupContent>
          </CanvasSideNav.NavigationGroup>
        )
      } else {
        if (seller && !item.unsetSellerId) {
          props.sellerId = seller.id
        }

        content = <SideNavItem key={kebabCase(item.text)} {...props} />
      }

      if (item.navGroup) {
        //Adds extra spacing between Services and Items
        if (i === 8 && !showInternalNav) {
          navItems.push(<ExpandedNavDivider key={i} />)
        }
        //Adds extra spacing between Review Queue and Item Review Tools
        else if (i === 6 && showInternalNav) {
          navItems.push(<ExpandedNavDivider key={i} />)
        } else {
          navItems.push(<NavDivider key={i} />)
        }
      }

      navItems.push(content)

      if (
        item.navGroup &&
        (items.length === i + 1 || items[i + 1].navGroup === undefined)
      ) {
        navItems.push(<NavDivider key={`tail`} />)
      }
    })

    return navItems
  }

  return (
    <CanvasSideNav
      suppressAutoOpen
      isVisible={open}
      isMinimized={!open}
      canMinimize={false}
      hasOverlay={false}
    >
      <StyledSideNavHeader>
        <IconButton
          onClick={() => setShowSideNav(false)}
          aria-label="Close Navigation Menu"
          size="large"
        >
          <MenuOpenIcon fontSize="large" />
        </IconButton>
      </StyledSideNavHeader>

      <StyledSideNavContainer className="hc-pa-none">
        {seller?.status === SellerStatus.SUSPENDED && (
          <StyledSideNavListItem className="hc-clr-contrast-weak">
            <StyledText variant="h6" data-testid="suspension-warning">
              {capitalize(SellerStatus.SUSPENDED)}
            </StyledText>
            <Tooltip title="Please contact Target+ support for more information.">
              <ErrorOutlineIcon className="hc-ml-sm hc-clr-error" />
            </Tooltip>
          </StyledSideNavListItem>
        )}
        {showInternalNav && generateInternalNav()}
        {showSellerNav && generateExternalNav()}
      </StyledSideNavContainer>
    </CanvasSideNav>
  )
}

export default SideNav
