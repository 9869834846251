import React, { PropsWithChildren } from 'react'

import Grid from '@mui/material/Grid'
import { useTheme, Theme, Breakpoint } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const itemWrap = (nodes: React.ReactNode[]) =>
  nodes.map((child, index) => (
    <Grid key={index} item xs={12}>
      {child}
    </Grid>
  ))

const TwoColumnLayout: React.FC<
  PropsWithChildren<{
    breakpoint?: Breakpoint
  }>
> = ({ children, breakpoint = 'lg' }) => {
  const theme = useTheme<Theme>()
  const isDouble = useMediaQuery(theme.breakpoints.up(breakpoint))

  if (!children) {
    return null
  }

  const nodes = children as React.ReactNode[]
  let content

  if (nodes.length === undefined) {
    content = (
      <Grid container spacing={2}>
        <Grid item xs={isDouble ? 6 : 12}>
          {nodes}
        </Grid>
      </Grid>
    )
  } else if (!isDouble) {
    content = (
      <Grid container spacing={2}>
        {itemWrap(nodes)}
      </Grid>
    )
  } else {
    const leftCol = [] as React.ReactNode[]
    const rightCol = [] as React.ReactNode[]

    nodes.forEach((node: any, index: number) => {
      if (index % 2 === 0) {
        leftCol.push(node)
      } else {
        rightCol.push(node)
      }
    })

    content = (
      <>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            {itemWrap(leftCol)}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            {itemWrap(rightCol)}
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <Grid container spacing={2}>
      {content}
    </Grid>
  )
}

export default TwoColumnLayout
