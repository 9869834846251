import { FC } from 'react'
import { Grid, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { StyledBreakdownSummaryCard, StyledDivider } from '../styles'

export interface SummaryInterface {
  order_status: string
  count: number
  tooltip?: string
}
interface MyProps {
  data?: SummaryInterface[]
}

const getOrdersCardTitle = (status: string): string => {
  const orderTitles: { [key: string]: string } = {
    SHIPPED: 'Total Shipped Orders',
    CANCELED: 'Cancelled Orders',
    RELEASED_FOR_SHIPMENT: 'Released Orders',
    PARTIALLY_SHIPPED: 'Partially Shipped',
    UNSHIPPED: 'Unshipped Orders',
    UNSHIPPED_PAST_DUE: 'Unshipped Orders Past Due',
  }
  return orderTitles[status] || ''
}

const SummaryCards: FC<MyProps> = ({ data }) => {
  const getCardContent = (val: SummaryInterface) => {
    return (
      <>
        <StyledBreakdownSummaryCard>
          <p className="hc-ma-none hc-ph-normal hc-pv-dense font-color-default hc-fs-sm ">
            {getOrdersCardTitle(val?.order_status)}
          </p>
          <p className="hc-ma-none hc-ph-normal font-color-default hc-fs-lg ">
            <strong>{val?.count}</strong>
          </p>
          <StyledDivider className="hc-pt-min" width={8} />
        </StyledBreakdownSummaryCard>
      </>
    )
  }
  return (
    <>
      <Grid.Container align="left" justify="flex-start">
        {data?.map((val) => {
          return (
            <Grid.Item>
              {val?.tooltip ? (
                <Tooltip content={val?.tooltip} location="top">
                  {getCardContent(val)}
                </Tooltip>
              ) : (
                getCardContent(val)
              )}
            </Grid.Item>
          )
        })}
      </Grid.Container>
    </>
  )
}

export default SummaryCards
