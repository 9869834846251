import { useRef, useState } from 'react'
import { HeadingBorder, StyledCards, StyledPaper } from './styles'
import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import { generateCsv } from './helpers'
import DrawerContainer from './DrawerContainer'
import { useStateValue } from 'stateManager/StateProvider'

export const UnlistItemsCards = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const { updateState } = useStateValue()

  const downloadLink = useRef<any>(null)

  const onTemplateDownload = () => {
    const csvData = [['TCIN']]
    const link = downloadLink.current
    const csv = generateCsv(csvData)
    link.setAttribute('href', csv)
    link.setAttribute('download', `bulk_unlist_template.csv`)
    link.click()
  }

  const toggleDrawer = () => {
    updateState({
      file: undefined,
      fileName: '',
      errorCode: '',
    })
    setIsDrawerVisible(!isDrawerVisible)
  }

  return (
    <div className="hc-mt-expanded">
      <Grid.Container
        justifyContent="space-between"
        data-testid="content-services"
      >
        <Grid.Item xs={12} sm={12} md={6} lg={6}>
          <StyledPaper>
            <HeadingBorder isBold={true} className="hc-fs-lg hc-pb-dense">
              Download Unlisting Template
            </HeadingBorder>
            <StyledCards className="display-flex hc-mt-normal">
              <p className="hc-mr-3x hc-clr-grey01">
                Team members may download the template for unlisting items from
                this card.
              </p>
              <Button onClick={onTemplateDownload} type="secondary">
                DOWNLOAD TEMPLATE
              </Button>
            </StyledCards>
          </StyledPaper>
        </Grid.Item>
        <Grid.Item xs={12} sm={12} md={6} lg={6}>
          <StyledPaper>
            <HeadingBorder isBold={true} className="hc-fs-lg hc-pb-dense">
              Upload Unlisting File
            </HeadingBorder>
            <StyledCards className="display-flex hc-mt-normal">
              <p className="hc-mr-3x hc-clr-grey01">
                Upload the unlisting file with TCINs in this section.
              </p>
              <Button type="primary" onClick={toggleDrawer}>
                UPLOAD FILE
              </Button>
            </StyledCards>
          </StyledPaper>
        </Grid.Item>
      </Grid.Container>
      <DrawerContainer
        onRequestClose={toggleDrawer}
        isDrawerVisible={isDrawerVisible}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a ref={downloadLink}>{''}</a>
    </div>
  )
}

export default UnlistItemsCards
