import { useEffect, useState } from 'react'

import * as yup from 'yup'
import { Form, FormikProps, withFormik } from 'formik'

import styled from '@emotion/styled'
import { grey, error } from 'config/themeConfig'

import { CircularProgress, Grid, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

import DialogContainer from 'components/common/Dialog/DialogContainer'
import InputField from 'components/common/form/InputField'
import FileUploader from 'components/common/FileUploader'
import RadioGroupField from 'components/common/form/RadioGroupField'

import CreateDisputeSummary from './CreateDisputeSummary'
import { ResponseText } from './FileReturnDisputeDialog'

import { maxCharacterCount, ValidationSchema } from 'services/validation'

import { Return } from 'types/Orders'

import {
  ACCEPTED_FILE_TYPES,
  FILE_TYPE_ERROR_MESSAGE,
} from 'constants/fileTypes'
import { MEGABYTES_AS_BYTES_1 } from 'constants/sizeLimits'
import { createReturnDisputeRequest } from 'services/returnDisputes'
import { CheckCircle } from '@mui/icons-material'

const StyledTypography = styled(Typography)({
  fontWeight: 'bold',
})

const StyledDiv = styled('div')({
  textAlign: 'center',
})

const StyledLabel = styled(Typography)({
  color: grey[700],
  fontSize: '0.8rem',
})

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}))

const MAX_CHARACTERS = 1000
const MAX_FILES = 6

interface FormValues {
  evidence: string
  description: string
  attachments: Dictionary<File>
}

export interface ComponentProps extends FormValues {
  isOpen: boolean
  sellerId: string
  order: Return
  licensePlate?: string
  disputeReason: string
  prettyDisputeReason: string
  disputeQuantity: number
  response: ResponseText
  handleSubmit: any
  handleCloseDialog: () => void
}

type Props = ComponentProps & FormikProps<FormValues>

export const validationSchema: ValidationSchema = yup.object().shape({
  description: yup
    .string()
    .label('Description')
    .test({
      name: 'isDescription',
      test: maxCharacterCount('description', 1000),
    })
    .required(),
  attachments: yup
    .object()
    .required()
    .test(
      'attachments',
      `Error: Maximum of ${MAX_FILES} attachments allowed`,
      (obj) => {
        if (!obj) {
          return true
        }
        return (
          Object.keys(obj).length > 0 && Object.keys(obj).length <= MAX_FILES
        )
      },
    ),
})

const NeedsReviewForm = ({
  isOpen,
  order,
  licensePlate,
  response,
  prettyDisputeReason,
  disputeQuantity,
  isValid,
  values,
  errors,
  setFieldValue,
  handleSubmit,
  handleCloseDialog,
  isSubmitting,
}: Props) => {
  const [page, setPage] = useState<number>(1)
  const [files, setFiles] = useState<Dictionary<File>>({})

  const successTitle = (
    <div>
      <CheckCircle
        color="success"
        fontSize="large"
        sx={{ verticalAlign: 'bottom' }}
      />{' '}
      DISPUTE SUBMITTED FOR REVIEW
    </div>
  )

  useEffect(() => {
    setFieldValue('attachments', files)
  }, [setFieldValue, files])

  const handleSubmitButton = () => {
    if (page === 1) {
      if (values.evidence === 'none') setPage(0)
      else setPage(2)
    } else if (page === 2) {
      handleSubmit()
      setPage(3)
    }
  }

  const handleFileRemove = (filename: string) => () => {
    const newFiles = Object.assign({}, files)
    delete newFiles[filename]
    setFiles(newFiles)
  }

  const radioOptions = [
    {
      label: 'Photo of returned item and packaging',
      value: 'photo',
    },
    {
      label: 'Tracking documentation',
      value: 'tracking',
    },
    {
      label: 'All of the above',
      value: 'all',
    },
    {
      label: 'None of the above',
      value: 'none',
    },
  ]

  return (
    <>
      <DialogContainer
        isOpen={isOpen}
        title={
          page === 0
            ? 'CANNOT BE DISPUTED'
            : page === 3 && !isSubmitting
              ? successTitle
              : 'FILE A RETURN DISPUTE'
        }
        isSubmitDisabled={page === 1 ? !values.evidence : !isValid}
        submitButtonText={page === 2 ? 'SUBMIT' : 'NEXT'}
        onSubmit={page === 0 || page === 3 ? undefined : handleSubmitButton}
        closeButtonText={page === 0 || page === 3 ? 'Close' : 'Cancel'}
        onCancel={() => {
          if (page === 3) handleCloseDialog()
        }}
      >
        {page !== 3 && (
          <CreateDisputeSummary
            order={order}
            licensePlate={licensePlate}
            disputeReason={prettyDisputeReason}
            disputeQuantity={disputeQuantity}
          />
        )}

        {page === 0 && (
          <StyledGrid>
            {response.text !== 'undefined' && (
              <StyledTypography sx={{ color: response?.color, pb: 2 }}>
                {response.text}
              </StyledTypography>
            )}
            <StyledTypography sx={{ color: error.main }}>
              Evidence supporting your dispute reason is required
            </StyledTypography>
          </StyledGrid>
        )}

        {page === 1 && (
          <StyledGrid>
            {response.text !== 'undefined' && (
              <StyledTypography sx={{ color: response?.color, pb: 2 }}>
                {response.text}
              </StyledTypography>
            )}
            <StyledTypography sx={{ pb: 1 }}>
              What evidence do you have to support this dispute?*
            </StyledTypography>
            <RadioGroupField required name="evidence" options={radioOptions} />
          </StyledGrid>
        )}

        {page === 2 && (
          <Form>
            <StyledGrid container>
              <Grid item xs={12}>
                <InputField
                  required
                  multiline
                  rows="4"
                  name="description"
                  label="Describe Your Issue Clearly with Specific Details"
                  maxCharacters={MAX_CHARACTERS}
                />
              </Grid>
              <StyledGrid item xs={12}>
                <StyledLabel>
                  Attach Evidence (Ex.: return package photo with CRC sticker,
                  item barcode)*
                </StyledLabel>
                <StyledDiv>
                  <FileUploader
                    accept={ACCEPTED_FILE_TYPES}
                    typeErrorMessage={FILE_TYPE_ERROR_MESSAGE}
                    onSubmit={(files) => {
                      setFiles((prevState) => ({
                        ...prevState,
                        ...(files as Dictionary<File>),
                      }))
                    }}
                    multiple
                    uploadInstructions="Maximum of 6 attachments allowed. Each attachment cannot be more than 1 MB. Attachments can be images (.jpg, .jpeg, .gif, .bmp, .png) or documents (.csv, .doc, .docm, .docx, .htm, .html, .pdf, .rtf, .xls, .xlsm, .xlsx)"
                    maxFileSize={MEGABYTES_AS_BYTES_1}
                  />
                </StyledDiv>
                {Object.keys(files).length > 0 && errors.attachments && (
                  <Typography color="error" sx={{ pt: 1 }}>
                    <>{errors.attachments}</>
                  </Typography>
                )}
                {Object.keys(files).map((filename, index) => (
                  <div key={index}>
                    <ClearIcon
                      onClick={handleFileRemove(filename)}
                      color="primary"
                    />
                    <Typography display="inline">{filename}</Typography>
                  </div>
                ))}
              </StyledGrid>
            </StyledGrid>
          </Form>
        )}

        {page === 3 && !isSubmitting && (
          <Typography>
            Thank you for your submittion! Dispute Resolution can take up to 5
            business days.
          </Typography>
        )}

        {isSubmitting && (
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </DialogContainer>
    </>
  )
}

export const NeedsReviewDialog = withFormik<ComponentProps, FormValues>({
  mapPropsToValues: () => ({ evidence: '', description: '', attachments: {} }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    if (!props.sellerId) {
      return
    }

    const { sellerId, order, licensePlate, disputeReason, disputeQuantity } =
      props
    const { description, attachments } = values

    let fileArray: File[] = []

    Object.keys(attachments).forEach((key) => {
      let value = attachments[key]
      fileArray.push(value)
    })

    await createReturnDisputeRequest(
      false,
      sellerId,
      order.return_order_number,
      order.tcin,
      disputeReason,
      disputeQuantity,
      licensePlate,
      description,
      fileArray,
    )

    setSubmitting(false)
  },
  validationSchema,
  enableReinitialize: true,
})(NeedsReviewForm)

export default NeedsReviewDialog
