import { useDispatch } from 'react-redux'
import { JSX } from 'react/jsx-runtime'

import { CancelOutlined, CheckCircle } from '@mui/icons-material'

import LaunchIcon from '@mui/icons-material/Launch'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import Link from 'components/common/Link'

import { openDialog } from 'store/dialog/actionCreator'

import { SetupTask } from 'types/SetupTask'

import styled from '@emotion/styled'
import { success, error } from 'config/themeConfig'

const StyledIconCell = styled(TableCell)(({ theme }) => ({
  width: theme.spacing(14),
}))

const StyledTitleCell = styled(TableCell)(({ theme }) => ({
  width: theme.spacing(50),
}))

const StyledCompleteIcon = styled(CheckCircle)({
  display: 'flex',
  alignItems: 'center',
  color: success.main,
})

const StyledIncompleteIcon = styled(CancelOutlined)({
  display: 'flex',
  alignItems: 'center',
  color: error.main,
})

const StyledLaunchIcon = styled(LaunchIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}))

export interface Props {
  task: SetupTask
}

export const SetupTaskTableRow = ({ task }: Props) => {
  const dispatch = useDispatch()

  const iconComponent = task.complete ? (
    <StyledCompleteIcon data-testid="icon-complete" />
  ) : (
    <StyledIncompleteIcon data-testid="icon-incomplete" />
  )
  let titleComponent: JSX.Element
  if (!task.enabled) {
    titleComponent = (
      <Typography data-testid="task-disabled">{task.title}</Typography>
    )
  } else if (task.link) {
    titleComponent = (
      <Link
        flexContainer
        to={task.link}
        target="_blank"
        data-testid="task-link"
      >
        {task.title} <StyledLaunchIcon fontSize="small" color="primary" />
      </Link>
    )
  } else if (task.dialogEnum) {
    titleComponent = (
      <Link
        onClick={(event) => {
          event.preventDefault()
          dispatch(openDialog({ dialogEnum: task.dialogEnum! }))
        }}
        to=""
        data-testid="task-dialog-link"
      >
        {task.title}
      </Link>
    )
  } else {
    throw new Error('Unknown task type')
  }

  return (
    <TableRow>
      <StyledIconCell>{iconComponent}</StyledIconCell>
      <StyledTitleCell>{titleComponent}</StyledTitleCell>
      <TableCell>
        <Typography>{task.description}</Typography>
      </TableCell>
    </TableRow>
  )
}

export default SetupTaskTableRow
