import axios from 'axios'

import apiConfig from 'config/apiConfig'

import {
  ReturnDisputeSearchParams,
  ReturnDisputeCasesSearchParams,
  ReturnDispute,
  ReturnDisputeSource,
} from 'types/disputes'
import { CollectionResponse } from 'types/Response'
import { Return } from 'types/Orders'
import { SmsSeller } from 'types/Seller'

import {
  createSortString,
  Direction,
  PagingParams,
  getPageable,
} from './pageableHelper'
import { getProductReturn } from 'services/productReturns'
import { getSeller } from 'services/seller'
import { getMarketplaceProductByTcin } from './items'
import { MarketplaceProduct } from 'types/Item'
import { getCaseById } from './cases'
import { Case } from 'types/Case'

export const fetchSellerReturnDisputes = (
  sellerId: string,
  searchParams: ReturnDisputeSearchParams,
): Promise<CollectionResponse<ReturnDispute>> => {
  return axios
    .get(`${apiConfig.sms}/sellers/${sellerId}/return_disputes`, {
      params: searchParams,
    })
    .then((response) => ({
      data: response.data,
      total: parseInt(response.headers['x-total-count'] ?? '0', 10),
    }))
}

export const fetchReturnDisputes = async (
  searchParams: ReturnDisputeCasesSearchParams,
  pagingParams: PagingParams,
): Promise<CollectionResponse<ReturnDispute>> => {
  const pageable = getPageable(pagingParams)
  const params = {
    ...pageable,
    ...searchParams,
  }

  return axios
    .get(`${apiConfig.sms}/return_disputes`, {
      params,
    })
    .then((response) => ({
      data: response.data,
      total: parseInt(response.headers['x-total-count'] ?? '0', 10),
    }))
}

export type InternalDisputeReview = {
  dispute: ReturnDispute
  seller?: SmsSeller
  productReturn?: Return
  item?: MarketplaceProduct
  selectedCase?: Case
}

export const fetchReturnDisputeForReview = async (
  searchParams: ReturnDisputeCasesSearchParams,
  pagingParams: PagingParams,
): Promise<InternalDisputeReview | null> => {
  const response = await fetchReturnDisputes(searchParams, pagingParams)

  const dispute = response.data?.[0]

  if (dispute) {
    const data = await fetchReviewDataForDispute(dispute)

    return data
  }
  return null
}

export const fetchReviewDataForDispute = async (dispute: ReturnDispute) => {
  const [seller, productReturn, item, selectedCase] = await Promise.all([
    getSeller(dispute.seller_id),
    getProductReturn(dispute.seller_id, dispute.product_return_id),
    getMarketplaceProductByTcin(dispute.tcin),
    getCaseById({ sellerId: dispute.seller_id, caseId: dispute.case_id }),
  ])

  const data: InternalDisputeReview = {
    dispute,
  }

  if (seller) {
    data.seller = seller
  }

  if (productReturn) {
    data.productReturn = productReturn
  }

  if (item) {
    data.item = item
  }

  if (selectedCase) {
    data.selectedCase = selectedCase
  }

  return data
}

export const updateReturnDisputeCase = async (data: ReturnDispute) => {
  return axios.put(
    `${apiConfig.sms}/sellers/${data.seller_id}/return_disputes/${data.id}`,
    data,
  )
}

export const fetchReturnDisputeCounts = (
  sellerId: string,
  searchParams: ReturnDisputeSearchParams,
): Promise<number> => {
  return fetchSellerReturnDisputes(sellerId, {
    ...searchParams,
    page: 1,
    per_page: 1,
  }).then((response) => response.total)
}

export const fetchAllReturnDisputes = async ({
  sellerId,
  caseId,
  source,
  per_page = 500,
  maxLoops = 5,
}: {
  sellerId: string
  caseId: string
  source: ReturnDisputeSource
  per_page?: number
  maxLoops?: number
}) => {
  let breaker = maxLoops // paranoia-driver loop breaker

  let disputes = [] as ReturnDispute[]
  let total = 0

  const search = {
    case_id: caseId,
    sort: createSortString('created', Direction.ASC),
    page: 1,
    per_page,
    source,
  }

  try {
    do {
      const { data, total: responseTotal } = await fetchSellerReturnDisputes(
        sellerId,
        search,
      )
      total = responseTotal // update total each time to catch additions as we are paging
      search.page = search.page + 1
      breaker-- // dec breaker

      disputes.push(...data)
    } while (breaker > 0 && total > disputes.length)
  } catch (e) {
    return []
  }

  return disputes
}

export const createReturnDisputeRequest = async (
  validate_only: boolean,
  seller_id: string,
  return_order_number: string,
  tcin: string,
  reason: string,
  quantity: number,
  license_plate?: string,
  description?: string,
  attachments?: File[],
) => {
  const data = new FormData()

  let param = {
    return_order_number,
    tcin,
    dispute_sub_reason: reason,
    quantity,
  }

  if (license_plate) {
    Object.assign(param, { license_plate })
  }

  if (description) {
    Object.assign(param, { description })
  }

  const json = JSON.stringify(param)

  data.append('dispute', new Blob([json], { type: 'application/json' }))

  attachments?.forEach((attachment) => {
    data.append('attachment', new Blob([attachment]), attachment.name)
  })

  const response = await axios.post(
    `${apiConfig.sms}/sellers/${seller_id}/return_disputes`,
    data,
    {
      params: {
        validate_only,
      },
    } as any,
  )

  return { data: response.data, status: response.status }
}
