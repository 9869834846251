import { useSelector } from 'react-redux'

import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import Address from 'components/common/Address'
import { DialogEnum } from 'components/common/Dialog'
import EditButton from 'components/common/EditButton'
import Link from 'components/common/Link'
import FormatPhoneNumber from 'components/common/PhoneNumber'
import TitleBar from 'components/common/TitleBar'
import TruncatedText from 'components/common/TruncatedText'

import { RoutePath } from 'services/NavigationHelper'
import { isReviewed } from 'services/reviewIndicators'

import { isRoleExternalUserSelector } from 'store/selectors'

import { Address as AddressType } from 'types/Address'
import { SellerStatus, ReviewIndicator, ReviewIndicators } from 'types/Seller'
import { PhoneNumber } from 'types/SellerUser'

const StyledFlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(3),
  alignItems: 'center',
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginRight: theme.spacing(0.5),
}))

const StyledReviewFlagButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const StyledLogoImage = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  maxHeight: 200,
  maxWidth: 200,
}))

const StyledBannerImage = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  maxHeight: 200,
  maxWidth: 1200,
}))

export interface Props {
  address: AddressType
  status: SellerStatus
  description: string | undefined
  displayName: string | undefined
  guestServicesEmail: string | undefined
  guestServicesPhone: PhoneNumber | undefined
  headerImage: string | undefined
  isImporter: boolean | undefined
  isManufacturer: boolean | undefined
  isReseller: boolean | undefined
  legalBusinessName: string | undefined
  logoImage: string | undefined
  privacyPolicy: string | undefined
  reviewIndicators: ReviewIndicator[]
  sellerId: string
  vmmId: string | undefined
  isEditableExternal: boolean
  isEditableInternal: boolean
}

export const NameAndAddressContent = ({
  address,
  status,
  description,
  displayName,
  guestServicesEmail,
  guestServicesPhone,
  headerImage,
  isImporter,
  isManufacturer,
  isReseller,
  legalBusinessName,
  logoImage,
  privacyPolicy,
  reviewIndicators,
  sellerId,
  vmmId,
  isEditableExternal,
  isEditableInternal,
}: Props) => {
  const isExternalUser = useSelector(isRoleExternalUserSelector)

  const isEditableInOnboarding =
    isEditableExternal && status === SellerStatus.PARTNER_SETUP

  const descriptionReviewStatus = isReviewed(
    reviewIndicators,
    ReviewIndicators.DESCRIPTION,
  )
  const privacyPolicyReviewStatus = isReviewed(
    reviewIndicators,
    ReviewIndicators.PRIVACY_POLICY,
  )
  const targetLink = `https://www.target.com/sp/-/-/N-${vmmId}`

  return (
    <div>
      <TitleBar
        title="Target.com Content"
        actionButtons={[
          <Button key="view-target-com" href={targetLink} color="primary">
            View Partner Page on Target.com
          </Button>,
        ]}
      />
      <Typography>
        *Required before items can be listed. Below is the information that will
        display on Target.com. To submit edits for this content, please{' '}
        <Link to={`/${sellerId}${RoutePath.OPEN_CASES}`} target="_blank">
          open a case
        </Link>
        .
      </Typography>
      <StyledFlexContainer>
        <StyledTitle>Legal Business Name*: </StyledTitle>
        {legalBusinessName}
        <EditButton
          dialogComponent={DialogEnum.EDIT_LEGAL_NAME}
          aria-label="edit legal name"
          hide={!isEditableInternal}
        />
      </StyledFlexContainer>
      <StyledFlexContainer>
        <StyledTitle>Display Name*: </StyledTitle>
        {displayName}
        <EditButton
          dialogComponent={DialogEnum.EDIT_DISPLAY_NAME}
          aria-label="edit display name"
          hide={!isEditableInternal}
        />
      </StyledFlexContainer>
      <StyledFlexContainer>
        <StyledTitle>Headquarters Address*</StyledTitle>
        <EditButton
          dialogComponent={DialogEnum.EDIT_PRIMARY_ADDRESS}
          aria-label="edit primary address"
          hide={isExternalUser ? !isEditableInOnboarding : !isEditableExternal}
        />
      </StyledFlexContainer>
      <Address address={address} />
      <StyledFlexContainer>
        <StyledTitle>Contact Info for Guest Inquiries*</StyledTitle>
        <EditButton
          dialogComponent={DialogEnum.EDIT_GUEST_CONTACT}
          aria-label="edit guest contact"
          hide={!isEditableInternal}
        />
      </StyledFlexContainer>
      <Typography>Email Address: {guestServicesEmail}</Typography>
      <Typography>
        Phone Number:{' '}
        {guestServicesPhone?.number ? (
          <FormatPhoneNumber phone={guestServicesPhone} />
        ) : (
          'No phone number provided'
        )}
      </Typography>
      <StyledFlexContainer>
        <StyledTitle>Product Sourcing Info*</StyledTitle>
        <EditButton
          dialogComponent={DialogEnum.EDIT_SOURCING_INFO}
          aria-label="edit product sourcing info"
          hide={!isEditableInternal}
        />
      </StyledFlexContainer>
      <Typography>
        Manufacturer of items listed:{' '}
        {isManufacturer === undefined
          ? 'None Provided'
          : isManufacturer
            ? 'Yes'
            : 'No'}
      </Typography>
      <Typography>
        Importer of items listed:{' '}
        {isImporter === undefined ? 'None Provided' : isImporter ? 'Yes' : 'No'}
      </Typography>
      <Typography>
        Reseller of items listed:{' '}
        {isReseller === undefined ? 'None Provided' : isReseller ? 'Yes' : 'No'}
      </Typography>
      <StyledFlexContainer>
        <StyledTitle>About Company*</StyledTitle>
        <EditButton
          dialogComponent={DialogEnum.EDIT_ABOUT_COMPANY}
          componentProps={{ canReview: isEditableInternal }}
          aria-label="edit about company"
          hide={
            !isEditableInternal &&
            !(isEditableInOnboarding && !descriptionReviewStatus)
          }
        />
        {isEditableInternal &&
          !descriptionReviewStatus &&
          description &&
          description.length > 0 && (
            <StyledReviewFlagButton
              data-testid="about-company-review-flag"
              disabled
              size="small"
              variant="outlined"
            >
              Needs Review
            </StyledReviewFlagButton>
          )}
      </StyledFlexContainer>
      {description && <TruncatedText text={description} showLineBreaks />}
      <StyledFlexContainer>
        <StyledTitle>Privacy Policy*</StyledTitle>
        <EditButton
          dialogComponent={DialogEnum.EDIT_PRIVACY_POLICY}
          componentProps={{ canReview: isEditableInternal }}
          aria-label="edit privacy policy"
          hide={
            !isEditableInternal &&
            !(isEditableInOnboarding && !privacyPolicyReviewStatus)
          }
        />
        {isEditableInternal &&
          !privacyPolicyReviewStatus &&
          privacyPolicy &&
          privacyPolicy.length > 0 && (
            <StyledReviewFlagButton
              data-testid="privacy-policy-review-flag"
              disabled
              size="small"
              variant="outlined"
            >
              Needs Review
            </StyledReviewFlagButton>
          )}
      </StyledFlexContainer>
      {privacyPolicy && <TruncatedText text={privacyPolicy} showLineBreaks />}
      <StyledFlexContainer>
        <StyledTitle>Logo &amp; Banner*</StyledTitle>
        <EditButton
          dialogComponent={DialogEnum.EDIT_LOGO_BANNER}
          aria-label="edit logo and banner"
          hide={!isEditableInternal}
        />
      </StyledFlexContainer>
      <StyledFlexContainer>
        <StyledLogoImage src={logoImage} alt={`Logo for ${displayName}`} />
        <StyledBannerImage
          src={headerImage}
          alt={`Banner for ${displayName}`}
        />
      </StyledFlexContainer>
    </div>
  )
}

export default NameAndAddressContent
