import React from 'react'
import { useDispatch } from 'react-redux'

import styled from '@emotion/styled'
import { grey } from 'config/themeConfig'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import DialogTransition from './DialogTransition'

import { closeDialog, unmountDialog } from 'store/dialog/actionCreator'

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'disableScroll',
})<{ disableScroll: boolean }>((props) => ({
  '& .MuiDialog-paperScrollPaper': {
    overflow: props.disableScroll ? 'visible' : undefined,
  },
}))

const StyledTitle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5, 2.5, 0, 2.5),
}))

const StyledSubtitle = styled(Typography)({
  color: grey[700],
  marginTop: 5,
})

const StyledFlexBoxLeft = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-start',
})

const StyledFlexboxRight = styled('div', {
  shouldForwardProp: (prop) => prop !== 'flexboxRight',
})<{ flexboxRight: number | undefined }>((props) => ({
  display: 'flex',
  flex: props.flexboxRight ? props.flexboxRight : 1,
  justifyContent: 'flex-end',
}))

const StyledFlexBoxCenter = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
})

interface Props {
  title: string | React.ReactNode
  isOpen: boolean
  subtitle?: string
  onSubmit?: (event: any) => void
  onCancel?: () => void
  onPrevious?: (event: React.MouseEvent<HTMLElement>) => void
  onDelete?: (event: React.MouseEvent<HTMLElement>) => void
  onNext?: (event: React.MouseEvent<HTMLElement>) => void
  cancelConfirmText?: string
  children?: React.ReactNode
  isPending?: boolean
  submitButtonText?: string
  closeButtonText?: string
  previousButtonText?: string
  previousButtonIcon?: boolean
  deleteButtonText?: string
  nextButtonText?: string
  isSubmitDisabled?: boolean
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  fullWidth?: boolean
  disableScroll?: boolean
  hideActions?: boolean
  closeOverride?: () => void
  disableClickAway?: boolean
  autoClose?: boolean
  page?: number
  totalPages?: number
  showCancelButton?: boolean
  flexboxRight?: number
}

export const DialogContainer = ({
  title,
  isOpen,
  subtitle,
  children,
  isPending = false,
  submitButtonText = 'Save',
  closeButtonText = 'Cancel',
  previousButtonText,
  previousButtonIcon = true,
  deleteButtonText = 'Delete',
  nextButtonText,
  showCancelButton = true,
  onSubmit,
  onCancel,
  onPrevious,
  onDelete,
  onNext,
  cancelConfirmText,
  closeOverride,
  disableClickAway,
  autoClose,
  isSubmitDisabled = false,
  maxWidth,
  fullWidth,
  disableScroll = false,
  hideActions = false,
  page,
  totalPages,
  flexboxRight,
}: Props) => {
  const dispatch = useDispatch()

  const handleCancel = React.useCallback(() => {
    if (cancelConfirmText) {
      if (window.confirm(cancelConfirmText)) {
        if (closeOverride) {
          closeOverride()
        } else {
          if (onCancel) {
            onCancel()
          }
          dispatch(closeDialog())
        }
      }
    } else {
      if (closeOverride) {
        closeOverride()
      } else {
        if (onCancel) {
          onCancel()
        }
        dispatch(closeDialog())
      }
    }
  }, [cancelConfirmText, closeOverride, dispatch, onCancel])

  const handleExited = () => {
    dispatch(unmountDialog())
  }

  React.useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined

    if (autoClose) {
      timer = setTimeout(() => {
        handleCancel()
      }, 2000)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [autoClose, handleCancel])

  // remove vertical scroll from the dialog content (i.e. Typeahead results need to be visible
  // but get cut off when the content is too short).
  // See: https://github.com/mui-org/material-ui/issues/7431#issuecomment-414703875

  return (
    <StyledDialog
      open={isOpen}
      onClose={(_event: any, reason: string) => {
        if (disableClickAway && reason === 'backdropClick') {
          return
        } else {
          handleCancel()
        }
      }}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll="paper"
      TransitionComponent={DialogTransition}
      data-testid="dialog"
      TransitionProps={{
        onExited: handleExited,
      }}
      disableScroll={disableScroll}
    >
      <div>
        <StyledTitle>
          <Typography variant="h2">{title}</Typography>
          {subtitle && (
            <StyledSubtitle variant="body1">{subtitle}</StyledSubtitle>
          )}
        </StyledTitle>
        <DialogContent
          sx={{
            padding: 2.5,
            overflow: disableScroll ? 'visible' : undefined,
          }}
        >
          {children}
        </DialogContent>
        {!hideActions && (
          <DialogActions sx={{ padding: 2.5 }}>
            <StyledFlexBoxLeft>
              {showCancelButton && onNext && (
                <Button
                  data-testid="dialog-cancel-button"
                  onClick={handleCancel}
                  disabled={isPending}
                  color="primary"
                >
                  {closeButtonText}
                </Button>
              )}
              {onPrevious && (
                <Button
                  data-testid="dialog-previous-button"
                  onClick={onPrevious}
                  disabled={isPending}
                  color="primary"
                  startIcon={
                    previousButtonIcon ? (
                      <ArrowBackIcon color="primary" />
                    ) : undefined
                  }
                >
                  {previousButtonText}
                </Button>
              )}
              {onDelete && (
                <Button
                  data-testid="dialog-delete-button"
                  onClick={onDelete}
                  disabled={isPending}
                  color="primary"
                >
                  {deleteButtonText}
                </Button>
              )}
            </StyledFlexBoxLeft>
            {!!page && totalPages && (
              <StyledFlexBoxCenter>
                {page} of {totalPages}
              </StyledFlexBoxCenter>
            )}
            <StyledFlexboxRight flexboxRight={flexboxRight}>
              {showCancelButton && !onNext && (
                <Button
                  data-testid="dialog-cancel-button"
                  onClick={handleCancel}
                  disabled={isPending}
                  color="primary"
                >
                  {closeButtonText}
                </Button>
              )}
              {onSubmit && (
                <Button
                  sx={{ marginLeft: 1 }}
                  data-testid="dialog-submit-button"
                  onClick={onSubmit}
                  disabled={isPending || isSubmitDisabled}
                  variant="contained"
                  color="primary"
                >
                  {submitButtonText}
                </Button>
              )}
              {onNext && (
                <Button
                  sx={{ marginLeft: 1 }}
                  data-testid="dialog-alternate-path-button"
                  onClick={onNext}
                  disabled={isPending || isSubmitDisabled}
                  variant="contained"
                  color="primary"
                >
                  {nextButtonText}
                </Button>
              )}
            </StyledFlexboxRight>
          </DialogActions>
        )}
      </div>
    </StyledDialog>
  )
}

export default DialogContainer
