import { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import styled from '@emotion/styled'

import { Grid, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

import Input from 'components/common/Input'
import Select from 'components/common/Select'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import FileUploader from 'components/common/FileUploader'

import { maxCharacterCount } from 'services/validation'
import { trackCustomEvent } from 'services/fireflyInsights'
import { saveCase } from 'services/cases'
import { validationHandler } from 'services/validation'

import { currentSellerId, email, getCaseTypes } from 'store/selectors'
import { closeDialog, openDialog } from 'store/dialog/actionCreator'

import { CaseType } from 'types/Enumeration'
import { Case, CreateCaseParams } from 'types/Case'
import { FireflyEvent } from 'types/FireflyInsights'
import { SellerMatch } from 'types/Seller'
import { Validation } from 'types/Validation'

import {
  ACCEPTED_FILE_TYPES,
  FILE_TYPE_ERROR_MESSAGE,
} from 'constants/fileTypes'
import { MEGABYTES_AS_BYTES_1 } from 'constants/sizeLimits'
import { DialogEnum } from 'components/common/Dialog'

interface ComponentProps {
  isOpen: boolean
  articleId?: string
  articleTitle?: string
}

type Props = ComponentProps

const validationSchema = yup.object().shape({
  subject: yup
    .string()
    .label('Subject')
    .test({
      name: 'isSubject',
      test: maxCharacterCount('subject', 50),
    })
    .required(),
  caseType: yup.string().label('Case Type').required(),
  sellerOrderNumber: yup
    .string()
    .label('Order ID')
    .test('len', 'Order ID must be 24 or 26 characters', (val) => {
      if (!val) {
        return true
      }
      return val.toString().length === 24 || val.toString().length === 26
    }),
  description: yup
    .string()
    .label('Description')
    .test({
      name: 'isDescription',
      test: maxCharacterCount('description', 1000),
    })
    .required(),
})

const StyledSpacer = styled('div')(({ theme }) => ({
  margin: theme.spacing(12),
}))

const StyledFileContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}))

const StyledClearIcon = styled(ClearIcon)(({ theme }) => ({
  margin: theme.spacing(0, 1, 0, 0),
  verticalAlign: 'middle',
  cursor: 'pointer',
}))

export const AddNewCase = ({ isOpen, articleId, articleTitle }: Props) => {
  const [caseType, setCaseType] = useState<string>()
  const [subject, setSubject] = useState<string>('')
  const [sellerOrderNumber, setSellerOrderNumber] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [files, setFiles] = useState<Dictionary<File>>({})
  const [validation, setValidation] = useState<Validation>({})

  const [isCasesPending, setIsCasesPending] = useState(false)
  const dispatch = useDispatch()

  const caseTypes = useSelector(getCaseTypes).filter(
    (item) =>
      item.value !== 'RETURNS_DISPUTES_V2' && item.value !== 'RETURNS_DISPUTES',
  )

  const sellerId = useSelector(currentSellerId)
  const userEmail = useSelector(email)

  const handleFileRemove = (filename: string) => () => {
    const newFiles = Object.assign({}, files)
    delete newFiles[filename]
    setFiles(newFiles)
  }

  const formatCaseTypes = (caseTypesList: CaseType[]) => {
    return caseTypesList.map((caseType: CaseType) => {
      return {
        ...caseType,
        caseTypeName: `${caseType?.properties?.level1} - ${caseType?.properties?.level2}`,
      }
    })
  }

  const onCaseTypeChange = (selectedCaseType: string) => {
    setCaseType(selectedCaseType)
    if (selectedCaseType && validation?.caseType?.length) {
      const { _caseType, ...remaining } = validation
      setValidation(remaining)
    }
  }

  const onOrderNumberChange = (value: string) => {
    setSellerOrderNumber(value)
    if (value && validation?.sellerOrderNumber?.length) {
      const { _sellerOrderNumber, ...remaining } = validation
      setValidation(remaining)
    }
  }

  const onOrderNumberBlur = () => {
    const hasOrderNumber =
      sellerOrderNumber &&
      sellerOrderNumber?.length !== 24 &&
      sellerOrderNumber?.length !== 26
    if (hasOrderNumber && !validation?.sellerOrderNumber?.length) {
      setValidation((prev) => ({
        ...prev,
        sellerOrderNumber: ['Order ID must be 24 or 26 characters'],
      }))
    }
  }

  const onSubjectChange = (value: string) => {
    setSubject(value)
    if (value && validation?.subject?.length) {
      const { _subject, ...remaining } = validation
      setValidation(remaining)
    }
  }

  const onDescriptionChange = (value: string) => {
    setDescription(value)
    if (value && validation?.description?.length) {
      const { _description, ...remaining } = validation
      setValidation(remaining)
    }
  }

  const handleSubmit = async () => {
    const { validation: formValidation, isValid } = validationHandler(
      validationSchema,
      { subject, sellerOrderNumber, description, caseType },
    )

    setValidation(formValidation)
    if (!isValid) return

    setIsCasesPending(true)
    const attachments = Object.values(files) as File[]

    try {
      const data = {
        sellerId,
        attachments,
        caseType,
        email: userEmail,
        description,
        subject,
        sellerOrderNumber,
      } as CreateCaseParams & SellerMatch

      const caseResponse = (await saveCase(data)) as Case
      if (articleId !== undefined && caseResponse.case_id) {
        trackCustomEvent(
          FireflyEvent.KB_ARTICLE_CASE_SUBMIT,
          caseResponse.case_id,
          articleId,
          articleTitle,
        )
      }

      dispatch(closeDialog())
      dispatch(
        openDialog({
          dialogEnum: DialogEnum.CASE_CREATED,
        }),
      )
    } catch (e) {
      console.error(`Error in AddNewCase: ${e}`)
      setIsCasesPending(false)
    }
  }

  return (
    <DialogContainer
      isOpen={isOpen}
      title="Submit a New Case"
      isPending={isCasesPending}
      onSubmit={handleSubmit}
      submitButtonText="Submit"
    >
      <Grid container spacing={2} data-testid="add-new-case">
        <Grid item xs={12}>
          <Input
            isRequired
            id="subject"
            name="subject"
            value={subject}
            label="Case Subject"
            inputProps={{
              'data-testid': 'subject',
              maxLength: 50,
            }}
            maxCharacters={50}
            isDisabled={isCasesPending}
            onChange={onSubjectChange}
            validation={validation}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            isRequired
            id="caseType"
            name="caseType"
            options={formatCaseTypes(caseTypes)}
            keyName="caseTypeName"
            valueName="value"
            label="Case Type"
            isDisabled={isCasesPending}
            data-testid="caseTypeSelect"
            onChange={onCaseTypeChange}
            value={caseType}
            validation={validation}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            id="sellerOrderNumber"
            name="sellerOrderNumber"
            label="Optional: Order ID"
            value={sellerOrderNumber}
            isDisabled={isCasesPending}
            inputProps={{ 'data-testid': 'sellerOrderNumber' }}
            onChange={onOrderNumberChange}
            onBlur={onOrderNumberBlur}
            validation={validation}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            isRequired
            isMultiline
            rows="4"
            value={description}
            id="description"
            name="description"
            label="Description"
            inputProps={{
              'data-testid': 'description',
              maxLength: 1000,
            }}
            maxCharacters={1000}
            isDisabled={isCasesPending}
            onChange={onDescriptionChange}
            validation={validation}
          />
        </Grid>
        {!caseType && <StyledSpacer />}
        {caseType && caseType !== 'RETURNS_DISPUTES' && (
          <Grid item xs={12}>
            <FileUploader
              accept={ACCEPTED_FILE_TYPES}
              typeErrorMessage={FILE_TYPE_ERROR_MESSAGE}
              onSubmit={(files) => {
                setFiles((prevState) => ({
                  ...prevState,
                  ...(files as Dictionary<File>),
                }))
              }}
              multiple
              uploadInstructions="Attachments can be images (.jpg, .jpeg, .gif, .bmp, .png) or documents (.csv, .doc, .docm, .docx, .htm, .html, .pdf, .rtf, .xls, .xlsm, .xlsx)"
              maxFileSize={MEGABYTES_AS_BYTES_1}
            />
            {Object.keys(files).map((filename, index) => (
              <StyledFileContainer key={index}>
                <StyledClearIcon
                  onClick={handleFileRemove(filename)}
                  color="primary"
                />
                <Typography display="inline">{filename}</Typography>
              </StyledFileContainer>
            ))}
          </Grid>
        )}
      </Grid>
    </DialogContainer>
  )
}

export default AddNewCase
