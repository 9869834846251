import { FC } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'

import LandingPageTopSection from './LandingPageTopSection'

const LandingPage: FC = () => {
  return (
    <Grid.Container>
      <Grid.Item xs={12}>
        <LandingPageTopSection />
      </Grid.Item>
    </Grid.Container>
  )
}

export default LandingPage
