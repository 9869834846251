import { startCase } from 'lodash/fp'
import { isAfter, isBefore, parseISO } from 'date-fns'
import saveAs from 'file-saver'

import { getCurrentCSTDateAndTime } from './date'

/**
 * Converts a given string to title case.
 * Title case means that the first letter of each word is capitalized.
 *
 * @param input - The string to be converted to title case. Defaults to an empty string.
 * @returns The converted string in title case.
 */
export const toTitleCase = (input: string = '') =>
  startCase(input.toLowerCase())

/**
 * Rounds a given number to two decimal places.
 *
 * @param number - The number to be rounded.
 * @returns The number rounded to two decimal places.
 */
export const roundToTwoDecimals = (number: number) =>
  typeof number === 'number' ? Math.round(number * 100) / 100 : null

/**
 * Extracts the file extension from a given string.
 *
 * @param input - The input string from which to extract the file extension. Defaults to an empty string.
 * @returns The file extension if present, otherwise undefined.
 */
export const getFileExtension = (input: string = '') => input.split('.').pop()

/**

 * Formats discount value based on the given type.
 *
 * @param type - The input string from which we decide the symbol
 * @param value - The discount value which needs to be formatted
 * @returns The formatted disocunt value.
 */
export const formatDiscountLabel = (type: string, value: number) => {
  if (!type) return
  if (type === 'PercentageOff') return ` ${value}%`
  else if (type === 'FixedPrice' || type === 'DollarOff') return `$${value}`
}

/**
 * Validates whether a given variable is an array or not
 *
 * @param arr - The array to be checked
 * @returns boolean value
 */
export const isArrayEmpty = (arr: any): boolean =>
  !Array.isArray(arr) || arr.length === 0

/**
 * Generates csv file with the passed row data
 *
 * @param rowData - The row data needs to be converted into a csv file
 * @returns encoded Uri
 */
export const generateCsv = (rowData: any[]) => {
  const data = Array.isArray(rowData)
    ? rowData.map((e) => e.join(',')).join('\n')
    : rowData
  const csvContent = new Blob([data], { type: 'text/csv;charset=utf-8' })
  const encodedUri = URL.createObjectURL(csvContent)
  return encodedUri
}

/**
 * Gives unique list of objects based on the based key
 *
 * @param arr - The array to be filtered
 * @param key - The key that needs to be used to filter the given array
 * @returns filtered list of objects
 */
export const getUniqueListBy = (arr: any[], key: any) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

/**
 * Determines the promotion status based on the provided start and end dates.
 *
 * @param startDate - The start date of the promotion in ISO string format.
 * @param endDate - The end date of the promotion in ISO string format.
 * @returns The promotion status which can be 'UPCOMING', 'ACTIVE', 'COMPLETED', or null if dates are invalid.
 */
export const getPromotionStatus = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) return null

  if (isBefore(getCurrentCSTDateAndTime(), parseISO(startDate))) {
    return 'UPCOMING'
  }

  if (isAfter(getCurrentCSTDateAndTime(), parseISO(endDate))) {
    return 'COMPLETED'
  }

  return 'LIVE'
}

/**
 * Saves the passed file with passed file name
 * @param file - The file that needs to be saved or downloaded
 * @param name - The name with which the file needs to be saved or downloaded
 */
export const saveFile = (file: File, name: string) => {
  saveAs(file, name)
}

/**
 * Converts the response into graph data with the given x-axis & y-axis values.
 *
 * @param {{
 *   response: any[];
 *   xValue: string;
 *   yValue: string;
 * }} params
 * @return {{ x_axis: any; y_axis: any }[]}
 */
export const transformToGraphAxes = ({
  response = [],
  xValue,
  yValue,
}: {
  response: any[]
  xValue: string
  yValue: string
}) => response.map((obj) => ({ x_axis: obj[xValue], y_axis: obj[yValue] }))
