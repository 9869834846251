import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { ReturnsResearch } from 'types/Orders'
import { PagingParams, getPageable } from './pageableHelper'
import { CollectionResponse } from 'types/Response'

export async function getReturnsResearch(
  pagingParams: PagingParams,
  searchParams: any,
): Promise<CollectionResponse<ReturnsResearch>> {
  const pageable = pagingParams ? getPageable(pagingParams) : {}

  const res = await axios.get(`${apiConfig.sellerReturns}/returns_research`, {
    params: { ...searchParams, ...pageable },
  })

  return {
    data: res.data,
    total: parseInt(res.headers['x-total-count'] ?? '0', 10),
  }
}
