import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { buildURLQueryParams } from 'v2/utils/url'
import { getCurrentDateWithStartAndEndTime } from 'v2/utils/date'

/**
 * gets orders to be shipped count
 * @param {{
 *   sellerId?: string
 * }} {
 *   sellerId,
 * }
 * @return {*}
 */
export const getOrdersToBeShipped = async ({
  sellerId,
}: {
  sellerId?: string
}) => {
  const params = buildURLQueryParams({
    requested_shipment_date: getCurrentDateWithStartAndEndTime(),
    seller_id: sellerId,
  })
  const url = `${apiConfig.sellerOrders}/orders_to_be_shipped?${params}`
  const response = await axios.get(url)
  return response?.data ?? []
}
