import { JSX, useState } from 'react'
import { useDispatch } from 'react-redux'

import { error, primary, success, warning } from 'config/themeConfig'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'

import WarningIcon from '@mui/icons-material/Warning'
import FlagIcon from '@mui/icons-material/Flag'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'

import Text from 'components/common/Text'

import { createMarkup } from 'services/createMarkup'

import { hideBanner } from 'store/notification/reducer'

import {
  Notification,
  NotificationUpdate,
  NotificationPriority,
} from 'types/Notifications'

type StyledProps = {
  backgroundColor?: string
}

const StyledRoot = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledMain = styled('div')(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(0, 0, 0, 3),
  borderRadius: theme.spacing(0, 0.5, 0.5, 0),
}))

const StyledFooter = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
})

const StyledDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  a: {
    color: primary.dark,
  },
}))

const StyledDivider = styled(Divider, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<StyledProps>((props) => ({
  backgroundColor: props.backgroundColor,
}))

const StyledContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<StyledProps>(
  (props) => ({
    backgroundColor: props.backgroundColor,
  }),
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
  }),
)

const StyledDividerContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<StyledProps>(
  (props) => ({
    backgroundColor: props.backgroundColor,
  }),
  ({ theme }) => ({
    padding: theme.spacing(0, 2, 0, 2),
  }),
)

function getPriorityStyles(priority: NotificationPriority): {
  lightColor: string
  mainColor: string
  icon?: JSX.Element
} {
  return priority === NotificationPriority.CRITICAL
    ? {
        lightColor: error.light,
        mainColor: error.main,
        icon: <WarningIcon sx={{ color: error.main }} fontSize="large" />,
      }
    : priority === NotificationPriority.WARNING
      ? {
          lightColor: warning.light,
          mainColor: warning.main,
          icon: <FlagIcon sx={{ color: warning.main }} fontSize="large" />,
        }
      : priority === NotificationPriority.INFORM
        ? {
            lightColor: success.light,
            mainColor: success.main,
            icon: (
              <NotificationsActiveIcon
                sx={{ color: success.main }}
                fontSize="large"
              />
            ),
          }
        : {
            lightColor: '',
            mainColor: '',
          }
}

interface Props {
  notifications: Notification[] | NotificationUpdate[]
  disabled?: boolean
}

export const Banner = ({ notifications, disabled }: Props) => {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState<boolean>(false)

  if (notifications.length === 0) {
    return null
  }

  let hasCriticalNotification = false
  if (
    notifications.find(
      (notification) => notification.priority === NotificationPriority.CRITICAL,
    )
  ) {
    hasCriticalNotification = true
  }

  const handleExpandClick = () => {
    setExpanded(true)
  }

  const handleShowLessClick = () => {
    setExpanded(false)
  }

  const handleDismissClick = () => {
    dispatch(hideBanner())
  }

  const formatNotificationMessage = (message: string) => {
    return (
      <div
        dangerouslySetInnerHTML={createMarkup(
          message.replace(
            /\[([^\]]+)\]\(([^)]+)\)/gim,
            '<a data-testid="banner-link" href="$2">$1</a>',
          ),
        )}
      />
    )
  }

  const priorityStyles = getPriorityStyles(notifications[0].priority)

  const DISMISS_BUTTON_TEXT = 'Dismiss'
  const SHOW_LESS_BUTTON_TEXT = 'Show Less'

  return (
    <StyledRoot data-testid="banner-root">
      {notifications.length === 1 && (
        <>
          <StyledContainer backgroundColor={priorityStyles.lightColor}>
            {priorityStyles.icon}
            <StyledMain>
              <StyledDiv>
                <Text data-testid="title" type="header">
                  {notifications[0].title}
                </Text>
              </StyledDiv>
              <StyledDiv>
                {formatNotificationMessage(notifications[0].message)}
              </StyledDiv>
              <StyledFooter>
                <Button
                  variant="text"
                  color="primary"
                  disabled={disabled}
                  onClick={
                    hasCriticalNotification ? undefined : handleDismissClick
                  }
                >
                  {hasCriticalNotification ? '' : DISMISS_BUTTON_TEXT}
                </Button>
              </StyledFooter>
            </StyledMain>
          </StyledContainer>
        </>
      )}
      {notifications.length > 1 && (
        <>
          <StyledContainer backgroundColor={priorityStyles.lightColor}>
            {priorityStyles.icon}
            <StyledMain>
              <StyledDiv>
                {!expanded && (
                  <Text data-testid="title" type="header">
                    {notifications[0].title} +{notifications.length - 1}{' '}
                    Additional Alert
                    {notifications.length - 1 > 1 ? 's' : null}
                  </Text>
                )}
                {expanded && (
                  <Text data-testid="title" type="header">
                    {notifications[0].title}
                  </Text>
                )}
              </StyledDiv>
              {expanded && (
                <StyledDiv>
                  {formatNotificationMessage(notifications[0].message)}
                </StyledDiv>
              )}
            </StyledMain>
            {!expanded && (
              <StyledFooter>
                <Button
                  data-testid="expand-notifications"
                  variant="text"
                  color="primary"
                  onClick={handleExpandClick}
                >
                  Learn More
                </Button>
              </StyledFooter>
            )}
          </StyledContainer>
          {expanded && (
            <StyledDividerContainer backgroundColor={priorityStyles.lightColor}>
              <StyledDivider
                backgroundColor={priorityStyles.mainColor}
                variant="middle"
              />
            </StyledDividerContainer>
          )}
          <Collapse in={expanded}>
            {notifications.slice(1).map((notification, index) => {
              const key = notification?.id ?? index
              return (
                <div key={key}>
                  <StyledContainer backgroundColor={priorityStyles.lightColor}>
                    {priorityStyles.icon}
                    <StyledMain>
                      <StyledDiv>
                        <Text data-testid="title" type="header">
                          {notification.title}
                        </Text>
                      </StyledDiv>
                      <StyledDiv>
                        {formatNotificationMessage(notification.message)}
                      </StyledDiv>
                      {index + 2 === notifications.length && (
                        <StyledFooter>
                          <Button
                            variant="text"
                            color="primary"
                            disabled={disabled}
                            onClick={
                              hasCriticalNotification
                                ? handleShowLessClick
                                : handleDismissClick
                            }
                          >
                            {hasCriticalNotification
                              ? SHOW_LESS_BUTTON_TEXT
                              : DISMISS_BUTTON_TEXT}
                          </Button>
                        </StyledFooter>
                      )}
                    </StyledMain>
                  </StyledContainer>
                  {index + 2 !== notifications.length && (
                    <StyledDividerContainer
                      backgroundColor={priorityStyles.lightColor}
                    >
                      <StyledDivider
                        backgroundColor={priorityStyles.mainColor}
                        variant="middle"
                      />
                    </StyledDividerContainer>
                  )}
                </div>
              )
            })}
          </Collapse>
        </>
      )}
    </StyledRoot>
  )
}

export default Banner
