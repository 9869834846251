import { Anchor, Button, Grid } from '@enterprise-ui/canvas-ui-react'
import ShipmentHistory from '../Filter/ShipmentHistory'
import EnterpriseIcon, { DownloadIcon } from '@enterprise-ui/icons'

const CtaButtons = () => {
  return (
    <Grid.Container justify="space-between" align="flex-end">
      <Grid.Item className="hc-pv-none ">
        <Anchor href="#" target="_blank">
          <Button type="primary">Help Articles</Button>
        </Anchor>
      </Grid.Item>
      <Grid.Item className="hc-pv-none">
        <ShipmentHistory />
      </Grid.Item>
      <Grid.Item className="hc-ph-none hc-pv-none">
        <Button type="ghost">
          <EnterpriseIcon className="hc-pr-min" icon={DownloadIcon} />
          Download
        </Button>
      </Grid.Item>
    </Grid.Container>
  )
}

export default CtaButtons
