import styled from '@emotion/styled'
import { Button, Chip } from '@enterprise-ui/canvas-ui-react'

export const StyledButton = styled(Button)`
  &.C-Button {
    height: 100%;
  }
`

export const StyledHourChip = styled(Chip)`
  &.C-Chip.\--clickable {
    border-color: ${(props: { is24Hours: boolean }) =>
      props.is24Hours
        ? 'var(--canvas-border-color--error)'
        : 'var(--canvas-border-color--alert)'};
  }
  &.C-Chip {
    background: ${(props: { is24Hours: boolean }) =>
      props.is24Hours
        ? 'var(--canvas-background-color--error-contrast-weak)'
        : 'var(--canvas-background-color--highlight)'};
  }
`
