import { Grid } from '@enterprise-ui/canvas-ui-react'
import { useAsyncSellerSelect } from 'v2/hooks/autoComplete'
import CtaButtons from './ctaButtons'
import { StyledCard } from '../styles'
import { useOrdersDashboardStore } from 'v2/store'
import { useEffect } from 'react'

const Header = () => {
  const { renderComponent, selectedOptions } = useAsyncSellerSelect()
  const { updateSellerId } = useOrdersDashboardStore()

  useEffect(() => {
    updateSellerId(selectedOptions?.id ?? '')
  }, [selectedOptions, updateSellerId])

  return (
    <StyledCard className="hc-bg-grey07 hc-ph-normal hc-mv-md">
      <Grid.Container direction="row" justify="space-between" align="center">
        <Grid.Item xs={5}>
          {renderComponent({ placeholder: 'Select Partner' })}
        </Grid.Item>
        <Grid.Item>
          <CtaButtons />
        </Grid.Item>
      </Grid.Container>
    </StyledCard>
  )
}

export default Header
