import { create } from 'zustand'
import { OrderStatus } from 'types/Orders'

type TimeFrame = {
  startDate: string
  endDate: string
}
const initialOrderFilter: OrderStatusInterface[] = [
  {
    id: OrderStatus.PARTIALLY_SHIPPED,
    label: OrderStatus.PARTIALLY_SHIPPED,
    value: OrderStatus.PARTIALLY_SHIPPED,
  },
  {
    id: OrderStatus.ACKNOWLEDGED_BY_SELLER,
    label: OrderStatus.ACKNOWLEDGED_BY_SELLER,
    value: OrderStatus.ACKNOWLEDGED_BY_SELLER,
  },
]
interface OrderStatusInterface {
  id: string
  label: string
  value: OrderStatus
}
interface OrdersDashboardState {
  partnerName: string | null
  orderStatuses: OrderStatusInterface[]
  orderPlacedDate: TimeFrame | null
  deliverByDate: TimeFrame | null
  requestShipDate: TimeFrame | null
  isOrdersAtRiskChecked: boolean
  ordersAtRiskCount: number
  sellerId?: string
  updatePartnerName: (partnerName: string) => void
  updateOrderStatuses: (orderStatus: OrderStatusInterface[]) => void
  updateOrderPlacedDate: (orderPlacedDate: TimeFrame) => void
  updateDeliverByDate: (deliverByDate: TimeFrame) => void
  updateRequestShipDate: (requestShipDate: TimeFrame) => void
  updateOrdersAtRiskCount: (ordersAtRiskCount: number) => void
  updateIsOrdersAtRiskChecked: (isOrdersAtRiskChecked: boolean) => void
  updateSellerId: (sellerId: string) => void
}

const useOrdersDashboardStore = create<OrdersDashboardState>((set) => ({
  partnerName: '',
  orderStatuses: initialOrderFilter,
  orderPlacedDate: null,
  deliverByDate: null,
  requestShipDate: null,
  ordersAtRiskCount: 0,
  isOrdersAtRiskChecked: false,
  sellerId: '',
  updatePartnerName: (partnerName) => set({ partnerName }),
  updateOrderStatuses: (orderStatuses) => set({ orderStatuses }),
  updateOrderPlacedDate: (orderPlacedDate) => set({ orderPlacedDate }),
  updateDeliverByDate: (deliverByDate) => set({ deliverByDate }),
  updateRequestShipDate: (requestShipDate) => set({ requestShipDate }),
  updateOrdersAtRiskCount(ordersAtRiskCount) {
    set({ ordersAtRiskCount })
  },
  updateIsOrdersAtRiskChecked: (isOrdersAtRiskChecked) =>
    set({ isOrdersAtRiskChecked }),
  updateSellerId: (sellerId) => set({ sellerId }),
}))

export default useOrdersDashboardStore
