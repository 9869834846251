import { Grid } from '@enterprise-ui/canvas-ui-react'

const OrderDetails = () => {
  return (
    <Grid.Container direction="column" className="hc-pv-lg">
      <Grid.Item>
        <p className="hc-fs-md hc-txt-uppercase">
          <strong>Total Order COUNT : 2,300</strong>
        </p>
      </Grid.Item>
      <Grid.Item>Orders table component</Grid.Item>
    </Grid.Container>
  )
}

export default OrderDetails
