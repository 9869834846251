//TODO: When the feature is complete delete the ./src/components/common/Filters components and rename this to `Filter`
import React from 'react'

import { useLocation } from 'react-router-dom'

import styled from '@emotion/styled'
import { Grid, Badge, Button, Tooltip, Typography } from '@mui/material'

import GetAppIcon from '@mui/icons-material/GetApp'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'

import Sticky from 'components/common/Sticky'
import { useUserSettingsContext } from 'components/context/UserSettingsProvider'
import SavedSearchSelectFilter from './SavedSearchSelectFilter'

import { boxShadow } from 'config/themeConfig'

import { formatLocaleNumber } from 'services/formatNumber'
import { getCurrentPage } from 'constants/userSettings'
import { SavedSearch } from 'types/SavedSearch'

const StyledFilterBarContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: `${theme.spacing(0.5)} ${theme.spacing(5)}`,
  marginRight: theme.spacing(-5),
  marginLeft: theme.spacing(-5),
  boxShadow,
}))

const StyledFilterContainer = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(1),
}))

const StyledTitleContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  justifyContent: 'space-between',
})

const StyledTitle = styled(Typography)({
  fontWeight: 700,
})

const StyledCount = styled('span')({
  fontWeight: 400,
})

const StyledBadge = styled(Badge)(({ theme }) => ({
  right: theme.spacing(-1),
  height: theme.spacing(2),
  minWidth: theme.spacing(2),
  padding: theme.spacing(0, 0.5),
}))

export type Props = {
  onClear?: () => void
  onDownload?: () => void
  handleSavedSearchSelect?: (savedSearch: SavedSearch) => void
  count?: number
  total?: number
  customCountTitle?: string
  customCountValue?: number
  isSticky?: boolean
  children: React.ReactNode
  appliedFilterCount?: number
  disableDownload?: boolean
  downloadTooltip?: string
  defaultCollapsed?: boolean
  page?: string
}

export const FilterBar = ({
  onClear,
  onDownload,
  handleSavedSearchSelect,
  count,
  total,
  customCountTitle,
  customCountValue,
  isSticky,
  children,
  appliedFilterCount,
  disableDownload,
  downloadTooltip,
  defaultCollapsed,
  page,
}: Props) => {
  const [hideFilters, setHideFilters] = React.useState(
    defaultCollapsed ?? false,
  )

  // Saved searches state
  const { userSettings, selectedSavedSearches } = useUserSettingsContext()
  const params = useLocation()
  const currentPage = getCurrentPage(params.pathname)
  const currentPageUserSettings =
    userSettings.data?.settings?.[`${currentPage}_saved_searches`]
  const savedSearches = Object.keys(currentPageUserSettings ?? {}).length
    ? currentPageUserSettings
    : false

  const lg = onClear ? 11 : 12

  const hasTitle = typeof count !== 'undefined'

  const countDisplay =
    total && count
      ? `${formatLocaleNumber(count)} (${((count / total) * 100).toFixed(1)}%)`
      : count
        ? formatLocaleNumber(count)
        : count

  const tooltipTitle = disableDownload && downloadTooltip ? downloadTooltip : ''

  const CollapseIcon = hideFilters ? UnfoldMoreIcon : UnfoldLessIcon

  return (
    <Sticky enabled={isSticky} top={112}>
      <StyledFilterBarContainer data-testid="filters">
        <StyledTitleContainer>
          <div>
            {hasTitle && (
              <StyledTitle variant="overline" sx={{ fontWeight: 700 }}>
                FILTERED COUNT:{' '}
                <StyledCount data-testid="review-queue-filtered-count">
                  {countDisplay}
                </StyledCount>
              </StyledTitle>
            )}
            {customCountTitle && customCountValue && (
              <>
                <StyledTitle variant="overline"> | </StyledTitle>
                <StyledTitle variant="overline">
                  {customCountTitle}:
                  <StyledCount>
                    {formatLocaleNumber(customCountValue)}
                  </StyledCount>
                </StyledTitle>
              </>
            )}
            {savedSearches && handleSavedSearchSelect && page && (
              <SavedSearchSelectFilter
                placeholder="Saved Filters"
                value={selectedSavedSearches[page]?.saved_search_name}
                data={Object.values(savedSearches)}
                onChange={handleSavedSearchSelect}
                onClear={onClear}
              />
            )}
          </div>
          <div>
            {onDownload && (
              <Tooltip title={tooltipTitle}>
                <span>
                  <Button
                    onClick={onDownload}
                    data-testid="download"
                    color="primary"
                    disabled={disableDownload}
                  >
                    <GetAppIcon /> Download Report
                  </Button>
                </span>
              </Tooltip>
            )}
            <Button
              onClick={() => setHideFilters((prev) => !prev)}
              data-testid="collapse-toggle"
              color="primary"
            >
              <CollapseIcon />
              {!hideFilters && <span>Hide Filters</span>}
              {hideFilters && (
                <StyledBadge
                  badgeContent={appliedFilterCount ?? 0}
                  color="error"
                >
                  Show Filters
                </StyledBadge>
              )}
            </Button>
          </div>
        </StyledTitleContainer>
        {!hideFilters && (
          <StyledFilterContainer container spacing={2}>
            <Grid item xs={12} lg={lg}>
              <Grid container spacing={2} alignItems="center">
                {children}
                {onClear && (
                  <Button
                    sx={{ marginTop: 2, marginLeft: 2, alignSelf: 'end' }}
                    color="primary"
                    variant="text"
                    onClick={onClear}
                  >
                    Clear all
                  </Button>
                )}
              </Grid>
            </Grid>
          </StyledFilterContainer>
        )}
      </StyledFilterBarContainer>
    </Sticky>
  )
}

export default FilterBar
