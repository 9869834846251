import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { buildURLQueryParams } from 'v2/utils/url'
import { OrderStatus } from 'types/Orders'
import { getCurrentDateWithStartAndEndTime } from 'v2/utils/date'

/**
 * Gets the orders count
 *
 * @param {{ sellerId: string }} { sellerId }
 * @return {*}
 */
export const getOrdersCounts = async ({ sellerId }: { sellerId?: string }) => {
  const params = buildURLQueryParams({
    order_status: `${OrderStatus.ACKNOWLEDGED_BY_SELLER}, ${OrderStatus.PARTIALLY_SHIPPED}`,
    requested_shipment_date: `/${getCurrentDateWithStartAndEndTime(true)}`,
    page: 1,
    per_page: 5,
    seller_id: sellerId,
  })

  const url = `${apiConfig.sellerOrders}/orders_counts?${params}`
  const response = await axios.get(url)

  return response?.data ?? []
}
