import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'
import {
  Bar,
  Tooltip,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { roundToTwoDecimals, transformToGraphAxes } from 'v2/utils/helper'
import { StyledCardContainer } from '../styles'
import { useQuery } from '@tanstack/react-query'
import { getOrdersCounts } from 'v2/services/getOrdersCounts'
import { useOrdersDashboardStore } from 'v2/store'

const UnshippedPastDueGraphByPartner = () => {
  const { sellerId } = useOrdersDashboardStore()
  const getData = async () => {
    const response = await getOrdersCounts({ sellerId })
    const data = transformToGraphAxes({
      response: response,
      xValue: 'seller_name',
      yValue: 'count',
    })
    return data
  }

  const { data, isFetching, isError } = useQuery({
    queryKey: ['UNSHIPPED_PAST_DUE_BY_PARTNER_GRAPH', sellerId],
    queryFn: getData,
  })

  if (isFetching) {
    return (
      <Placeholder className="hc-pv-xl">
        <Grid.Container justify="center" align="center">
          <Grid.Item xs={12}>
            <Placeholder.Rect emphasized height="270px" />
          </Grid.Item>
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <p className="hc-pa-expanded hc-ta-center">
        There was an error fetching the data.
      </p>
    )
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          layout="vertical"
          data={data}
          margin={{ top: 40, right: 30, left: 0, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis className="hc-fs-min" dataKey="y_axis" dy={14} type="number" />
          <YAxis
            className="hc-fs-min"
            dataKey="x_axis"
            type="category"
            tick={{ fontSize: 11 }}
            width={140}
          />

          <Tooltip
            cursor={{ fill: 'transparent' }}
            labelFormatter={() => ''}
            formatter={(value: any) => [`${roundToTwoDecimals(value)}`]}
          />
          <Bar
            dataKey="y_axis"
            fill="#888888"
            barSize={20}
            radius={[0, 5, 5, 0]}
          >
            <LabelList
              className="hc-fs-min"
              dataKey="y_axis"
              position="insideRight"
              fill="var(--canvas-background-color--input)"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      <Grid.Container className="hc-mv-normal" align="center">
        <StyledCardContainer className="hc-bg-grey02 hc-ml-2x hc-pa-dense" />
        <Grid.Item>
          <p className="hc-fs-min hc-txt-capitalize">Order Count</p>
        </Grid.Item>
      </Grid.Container>
    </>
  )
}
export default UnshippedPastDueGraphByPartner
