import { useEffect, useState } from 'react'
import DialogContainer from 'components/common/Dialog/DialogContainer'

export interface Props {
  isOpen: boolean
  onClose: () => void
  onContinue: () => void
}

export const InactivityDialog = ({ isOpen, onClose, onContinue }: Props) => {
  const INACTIVITY_TIMEOUT = 60

  const [countdown, setCountdown] = useState(INACTIVITY_TIMEOUT)

  useEffect(() => {
    let countdownInterval: ReturnType<typeof setInterval>

    if (isOpen) {
      // Start countdown when dialog is opened
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)
    }

    return () => clearInterval(countdownInterval)
  }, [isOpen])

  useEffect(() => {
    if (countdown === 0) {
      onClose()
    }
  }, [countdown, onClose])

  return (
    <DialogContainer
      data-testid="inactivity-dialog"
      title="Are you still there?"
      isOpen={isOpen}
      showCancelButton={false}
      previousButtonText="No, return to queue"
      previousButtonIcon={false}
      onPrevious={onClose}
      onSubmit={onContinue}
      submitButtonText="Yes, continue reviewing"
      disableClickAway
      maxWidth="sm"
    >
      <p>
        {`Due to inactivity, your review session is about to time out and you will
        automatically be returned to the Review Queue in `}
        <strong>{`${countdown}`}</strong> {`seconds.`}
      </p>
      <p>Do you want to continue reviewing the current item?</p>
    </DialogContainer>
  )
}

export default InactivityDialog
