import { FC, useState } from 'react'
import { Chip, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import EnterpriseIcon, { CheckIcon } from '@enterprise-ui/icons'

import { StyledButton, StyledHourChip } from '../../styles'

const Filters: FC = () => {
  const [is24HoursSelected, setIs24HoursSelected] = useState(false)
  const [is48HoursSelected, setIs48HoursSelected] = useState(false)
  const [is72HoursSelected, setIs72HoursSelected] = useState(false)

  return (
    <Grid.Container>
      <Grid.Item xs={6}>
        <Heading size={6}>Time Remaining</Heading>
        <Grid.Container>
          <Grid.Item className="hc-ph-min hc-pv-normal">
            <StyledHourChip
              is24Hours={true}
              clickable
              onClick={() => setIs24HoursSelected((prev) => !prev)}
            >
              {is24HoursSelected && <EnterpriseIcon icon={CheckIcon} />}
              Less than 24Hrs
            </StyledHourChip>
          </Grid.Item>
          <Grid.Item className="hc-ph-min hc-pv-normal">
            <StyledHourChip
              clickable
              onClick={() => setIs48HoursSelected((prev) => !prev)}
            >
              {is48HoursSelected && <EnterpriseIcon icon={CheckIcon} />}
              24 - 48Hrs
            </StyledHourChip>
          </Grid.Item>
          <Grid.Item className="hc-ph-min hc-pv-normal">
            <Chip
              clickable
              onClick={() => setIs72HoursSelected((prev) => !prev)}
            >
              {is72HoursSelected && <EnterpriseIcon icon={CheckIcon} />}
              48 - 72Hrs
            </Chip>
          </Grid.Item>
        </Grid.Container>
      </Grid.Item>
      <Grid.Item xs={4}>
        <Heading size={6}>Item Type</Heading>
        <Autocomplete />
      </Grid.Item>
      <Grid.Item xs={2}>
        <StyledButton type="ghost">Clear All</StyledButton>
      </Grid.Item>
    </Grid.Container>
  )
}

export default Filters
