import { useState, useEffect, SyntheticEvent } from 'react'
import usePrevious from 'hooks/usePrevious'

import ValidationErrorText from 'components/common/ValidationErrorText'

import { ErrorCode } from 'types/ErrorCode'
import { Validation } from 'types/Validation'

import { searchErrorCodes } from 'services/errorCodes'
import Typeahead from '.'

interface Props {
  placeholder?: string
  onChange: (value: Nullable<ErrorCode>, enteredValue: string) => void
  isDisabled?: boolean
  value?: Nullable<ErrorCode>
  clearOnSelect?: boolean
  name?: string
  validation?: Validation
  hideDeleted?: boolean
}

const ErrorCodeTypeahead = ({
  placeholder = 'Search error codes or text',
  onChange,
  isDisabled,
  value: valueFromProps,
  clearOnSelect = false,
  name,
  validation,
  hideDeleted = false,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValue, setSelectedValue] =
    useState<Nullable<ErrorCode>>(valueFromProps)
  const [options, setOptions] = useState<ErrorCode[]>([])
  const [loading, setLoading] = useState(false)

  const prevValue = usePrevious(valueFromProps)

  useEffect(() => {
    if (prevValue !== valueFromProps) {
      setSelectedValue(valueFromProps)
    }

    if (!valueFromProps) {
      setOptions([])
    }
  }, [prevValue, valueFromProps])

  useEffect(() => {
    if (options.length && !inputValue) {
      setOptions([])
    }
  }, [inputValue, options])

  const handleInputChange = async (
    _event: SyntheticEvent,
    enteredValue: string,
    reason: string,
  ) => {
    if (
      reason === 'clear' ||
      (selectedValue && reason === 'input' && enteredValue === '')
    ) {
      setOptions([])
      setInputValue('')
      setSelectedValue(null)
      return
    }

    setInputValue(enteredValue)
    const invalidInputValue = !enteredValue || reason === 'reset'
    if (invalidInputValue) {
      return
    }

    setLoading(true)
    await searchErrorCodes(enteredValue).then((response) => {
      const filteredOptions = response.filter((errorCode) => {
        // users are not allowed to add 7xx or 8xx errors
        return hideDeleted
          ? errorCode.status === 'ACTIVE' &&
              (errorCode.error_code < 700 || errorCode.error_code > 899)
          : errorCode.error_code < 700 || errorCode.error_code > 899
      })

      setOptions(filteredOptions)
    })
    setLoading(false)
  }

  const handleSelectedOptionChange = (
    _event: SyntheticEvent,
    value: Nullable<ErrorCode>,
  ) => {
    if (!(value instanceof Array)) {
      const searchParamValue = getOptionLabel(value as ErrorCode)
      setSelectedValue(clearOnSelect ? null : (value as Nullable<ErrorCode>))
      onChange(value as Nullable<ErrorCode>, searchParamValue)
    }
  }

  const getOptionLabel = (errorCode: ErrorCode) =>
    `${errorCode.error_code}: ${errorCode.reason}`

  return (
    <div data-testid="error-typeahead">
      <Typeahead
        aria-label="Error code typeahead"
        placeholder={placeholder}
        disabled={!!isDisabled}
        options={options}
        loading={loading}
        getOptionLabel={getOptionLabel}
        onInputChange={handleInputChange}
        onChange={handleSelectedOptionChange}
        inputValue={inputValue}
        value={selectedValue || null}
        isOptionEqualToValue={(option: ErrorCode, value: Nullable<ErrorCode>) =>
          option.error_code === value?.error_code
        }
      />
      {validation && (
        <div data-testid="validation">
          <ValidationErrorText errors={validation} field={name} />
        </div>
      )}
    </div>
  )
}

export default ErrorCodeTypeahead
