import axios from 'axios'
import uniq from 'lodash/fp/uniq'

import apiConfig from 'config/apiConfig'

import { getBrandAttributes, getBrandAttributesFromCache } from './brands'
import { getNodesById } from './itemTaxonomies'
import { Pageable } from './pageableHelper'

import Attribute, { BRAND, ITEM_SUBTYPE, ITEM_TYPE } from 'types/Attribute'
import { Legalization, ProtectedLegalization } from 'types/Legalization'
import { CollectionResponse } from 'types/Response'

export function getSellerLegalizations(
  { id }: { id: string },
  pageable: Pageable,
) {
  const config = {
    params: {
      ...pageable,
    },
  }

  return axios
    .get(`${apiConfig.sms}/sellers/${id}/legalizations`, config)
    .then((res) => {
      const { data, headers = {} } = res
      const totalCount = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        data,
        totalCount,
      }
    })
}

export async function getSellerLegalizationsWithNames(
  sellerId: string,
): Promise<Legalization[]> {
  let sellerLegalizations: Legalization[] = []
  let page = 1
  const firstPage = await getSellerLegalizations(
    { id: sellerId },
    { page, per_page: 1000 },
  )
  sellerLegalizations = sellerLegalizations.concat(firstPage.data)

  // Continue to get legalizations in blocks of {rowsPerPage} and concat them to our local array.
  while (sellerLegalizations.length < firstPage.totalCount) {
    page = page + 1

    const response = await getSellerLegalizations(
      { id: sellerId },
      { page, per_page: 1000 },
    )

    sellerLegalizations = sellerLegalizations.concat(response.data)
  }

  const brandIds: string[] = getAttributeIdsFromLegalizations(
    sellerLegalizations,
    BRAND,
  )

  const itemTypeIds: string[] = getAttributeIdsFromLegalizations(
    sellerLegalizations,
    ITEM_TYPE,
  )
  const subtypeIds: string[] = getAttributeIdsFromLegalizations(
    sellerLegalizations,
    ITEM_SUBTYPE,
  )

  const nodeIds = [...itemTypeIds, ...subtypeIds]

  // get the attributes with names from brand and nexus for each unique id
  const brandAttributes = await getBrandAttributesFromCache(uniq(brandIds))
  const nodeAttributes = await getNodesById(uniq(nodeIds))

  // update legalization attributes with the names
  sellerLegalizations.forEach((legalization) => {
    const isBrand = legalization.primary_attribute.type === BRAND
    legalization.primary_attribute = mapAttribute(
      legalization.primary_attribute,
      isBrand ? brandAttributes : nodeAttributes,
    )
    legalization.paired_attributes = mapPairedAttributes(
      legalization.paired_attributes,
      isBrand ? nodeAttributes : brandAttributes,
    )
  })

  return sellerLegalizations
}

export function updateSellerLegalization(
  sellerId: string,
  id: string | undefined,
  data: Legalization,
): Promise<Legalization> {
  const action = id ? 'put' : 'post'
  const urlParam = id ? `/${id}` : ''
  const url = `${apiConfig.sms}/sellers/${sellerId}/legalizations${urlParam}`

  return axios({
    method: action,
    url,
    data,
  }).then((res) => res.data as any)
}

export function deleteSellerLegalization(
  sellerId: string,
  legalizationId: string,
): Promise<any> {
  const url = `${apiConfig.sms}/sellers/${sellerId}/legalizations/${legalizationId}`

  return axios.delete(url)
}

function mapPairedAttributes(
  pairedAttributes: Attribute[] | undefined,
  namedAttributes: Attribute[],
) {
  if (!pairedAttributes) return pairedAttributes
  return pairedAttributes.map((pairedAttr) =>
    mapAttribute(pairedAttr, namedAttributes),
  )
}

function mapAttribute(attribute: Attribute, namedAttributes: Attribute[]) {
  const namedAttribute = namedAttributes.find(
    (attr) => attr.id === attribute.id,
  )
  return namedAttribute || attribute
}

export function getAttributeIdsFromLegalizations(
  legalizations: Legalization[],
  type: string,
): string[] {
  return legalizations.flatMap((legalization: Legalization) =>
    getAttributeIdsForType(legalization, type),
  )
}

export function getAttributeIdsForType(
  legalization: Legalization,
  type: string,
): string[] {
  const ids: string[] = []

  if (legalization.primary_attribute.type === type) {
    ids.push(legalization.primary_attribute.id)
  } else if (legalization.paired_attributes) {
    ids.push(
      ...legalization.paired_attributes
        .filter((attr) => attr.type === type)
        .map((attr) => attr.id),
    )
  }
  return ids
}

export function getNamesForType(
  legalization: Legalization,
  type: string,
): string[] {
  const names: string[] = []

  if (legalization.primary_attribute.type === type) {
    const text = getItemTypeNameOrDeprecationWarning(
      legalization.primary_attribute,
    )
    names.push(text)
  } else if (legalization.paired_attributes) {
    names.push(
      ...legalization.paired_attributes
        .filter((attr) => attr.type === type)
        .map((attr) => getItemTypeNameOrDeprecationWarning(attr)),
    )
  }

  return names
}

export function getItemTypeNameOrDeprecationWarning(
  attribute: Attribute,
): string {
  const itemTypeName = attribute.name || attribute.id // We want to return a string but name might be undefined. Not sure why

  const text = attribute.deprecated
    ? `Item Type ${attribute.id} no longer exists`
    : itemTypeName

  if (!attribute.name) {
    console.error(`Item Type ${attribute.id} does not have a name.`)
  }
  return text
}

export function getProtectedLegalizations(
  type: string,
  pageable: Pageable,
): Promise<CollectionResponse<ProtectedLegalization>> {
  const config = { params: { ...pageable } }

  return axios
    .get(`${apiConfig.sms}/protected_legalizations?type=${type}`, config)
    .then((res): CollectionResponse<ProtectedLegalization> => {
      const { data, headers } = res
      const total = headers['x-total-count']
        ? parseInt(headers['x-total-count'], 10)
        : 0

      return {
        total,
        data,
      }
    })
}

export async function getProtectedLegalizationsWithNames(
  type: string,
  pageable: Pageable,
): Promise<CollectionResponse<ProtectedLegalization>> {
  const { data: legalizations, total } = await getProtectedLegalizations(
    type,
    pageable,
  )

  const ids: string[] = legalizations.map(
    (legalization) => legalization.attribute.id,
  )

  const attributes = await getBrandAttributes(uniq(ids))

  legalizations.forEach((legalization: ProtectedLegalization) => {
    const target = attributes.find(
      (attribute) => attribute.id === legalization.attribute.id,
    )

    legalization.attribute.name = target
      ? target.name
      : legalization.attribute.id
  })

  return {
    data: legalizations,
    total,
  }
}

export async function addProtectedLegalization(id: string) {
  const data = { attribute: { id, type: 'BRAND' } }

  try {
    await axios.post(`${apiConfig.sms}/protected_legalizations`, data)
  } catch (e: unknown) {
    if (e instanceof Error) {
      throw new Error(`Failed to add protected brand: ${e.message}`)
    }
  }
}

export async function removeProtectedLegalization(id: string) {
  try {
    await axios.delete(`${apiConfig.sms}/protected_legalizations/${id}`)
  } catch (e: unknown) {
    if (e instanceof Error) {
      throw new Error(`Failed to delete protected brand: ${e.message}`)
    }
  }
}
