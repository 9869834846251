import { HeadingBorder } from './styles'

export const UnlistItemsDescription = () => {
  return (
    <>
      <HeadingBorder className="hc-fs-xl">Item Unlisting Tool</HeadingBorder>
      <div className="hc-lh-dense hc-mt-dense">
        <p className="hc-clr-grey01 hc-mt-expanded" data-testid="tool-desc">
          Item unlisting tool allows Target+ operations team the control to
          unlist items for business requirements including out of stock issues.{' '}
        </p>
        <p className="hc-clr-grey01">
          <strong>Barcode release: </strong>Each unlisting reason has a specific
          barcode release rule (e.g., release after a 120-day post-order check
          or immediate release). Once a barcode is released, sellers may
          encounter issues when pushing a new version if the barcode has already
          been claimed. Please review and validate all requests carefully before
          proceeding.
        </p>
        <p className="hc-clr-grey01">
          <strong>Relisting the TCIN:</strong> Once an item is unlisted, TM's
          need to reach out to the seller if they wish to relist the TCIN. If
          the barcode is released and is not assigned to any other seller, they
          can update and push the item again from the channel partner console/
          direct API and the barcode will be reassigned.
        </p>
      </div>
    </>
  )
}

export default UnlistItemsDescription
