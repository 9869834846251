import { FC } from 'react'
import HeaderTitle from 'components/common/HeaderTitle'

const NotificationSettings: FC = () => {
  return (
    <>
      <HeaderTitle title="Notification Settings"></HeaderTitle>
    </>
  )
}

export default NotificationSettings
