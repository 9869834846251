import { useState, CSSProperties } from 'react'
import AsyncSelect from 'react-select/async'
import { MultiValue, SingleValue } from 'react-select'

import { getItemTypeTaxonomies } from 'v2/services/getItemTypeTaxonomies'

interface Option {
  label: string
  value: string
}

const useAsyncItemTypeSelect = () => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<Option>>([])

  const fetchOptions = async (inputValue: string): Promise<Option[]> => {
    if (!inputValue) {
      return []
    }

    const data = await getItemTypeTaxonomies({
      name: inputValue,
      type: 'item_type',
    })

    return data.map((item: { label: string; id: string }) => ({
      label: item.label,
      value: item.id,
    }))
  }

  const handleInputChange = (newValue: string): string => {
    const value = newValue.replace(/\W/g, '') // remove any non-alphanumeric characters from the string
    return value
  }

  const handleChange = (newValue: MultiValue<Option> | SingleValue<Option>) => {
    if (Array.isArray(newValue)) {
      setSelectedOptions(newValue)
    }
  }

  const renderComponent = ({
    placeholder = 'Select an option...',
    isMulti = false,
  }: {
    placeholder?: string
    isMulti?: boolean
  }) => (
    <AsyncSelect
      cacheOptions
      isMulti={isMulti}
      loadOptions={fetchOptions}
      onChange={handleChange}
      value={selectedOptions}
      onInputChange={handleInputChange}
      placeholder={placeholder}
      closeMenuOnSelect={false}
      styles={{
        control: (baseStyles: CSSProperties) => ({
          ...baseStyles,
          lineHeight: 1,
          borderRadius: 8,
          borderColor: '#2b2d31',
        }),
        indicatorSeparator: (baseStyles: CSSProperties) => ({
          ...baseStyles,
          display: 'none',
        }),
        clearIndicator: (baseStyles: CSSProperties) => ({
          ...baseStyles,
          color: '#2b2d31',
        }),
        dropdownIndicator: (baseStyles: CSSProperties) => ({
          ...baseStyles,
          color: '#2b2d31',
        }),
      }}
    />
  )

  return { renderComponent, selectedOptions }
}

export default useAsyncItemTypeSelect
