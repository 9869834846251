import styled from '@emotion/styled'
import { Card, Divider, Grid } from '@enterprise-ui/canvas-ui-react'

export const StyledBreakdownSummaryCard = styled(Card)`
  width: 300px;
  border: 0.5px solid #0037a6;
  height: 80px;
  border-radius: 4px;
`

export const StyledDivider = styled(Divider)`
  &.C-Divider.\--horizontal .C-Divider__line {
    border-radius: 0 0 8px 8px;
    background-color: #d1ddf5;
  }
`

export const StyledGridContainerFilter = styled(Grid.Container)`
  background-color: #f7f7f7;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
`
export const StyledCardForGraphContainer = styled(Card)`
  &.C-Card {
    min-height: 430px;
  }
  .C-Input--type-label {
    font-size: 16px;
    font-weight: bold;
`
export const StyledCard = styled(Card)`
  &.C-Card {
    border-radius: 0;
  }
  .C-Heading {
    font-weight: 600;
    text-transform: uppercase;
  }
`

export const StyledCardContainer = styled(Card)`
  &.C-Card {
    background-color: var(--canvas-font-color--interactive-contrast);
    border-radius: 5px;
    padding-top: 10px;
  }
`
