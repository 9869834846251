import { useEffect, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { OrderStatus } from 'types/Orders'
import { useOrdersDashboardStore } from 'v2/store'
import { getCurrentDateWithStartAndEndTime } from 'v2/utils/date'
import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'
import { isRoleExternalUserSelector } from 'store/selectors'
import CtaButtons from '../Header/ctaButtons'
import { getOrderStatusCounts } from 'v2/services/getOrderStatusCount'
import SummaryCards from './SummaryCards'
import { getUnshippedOrdersAge } from 'v2/services/getUnshippedOrdersAge'

const BreakdownSummary = () => {
  const { sellerId, ordersAtRiskCount, updateOrdersAtRiskCount } =
    useOrdersDashboardStore()
  const isExternalUser = useSelector(isRoleExternalUserSelector)

  // orders at risk tool tip count api call
  useEffect(() => {
    const getOrdersAtRisk = async () => {
      const response = await getUnshippedOrdersAge({
        date: getCurrentDateWithStartAndEndTime(),
        sellerId,
      })
      updateOrdersAtRiskCount(response?.ordersAtRisk)
    }

    getOrdersAtRisk()
  }, [sellerId, updateOrdersAtRiskCount])

  const getBreakdownSummaryData = async () => {
    const resp = await getOrderStatusCounts({
      orderStatus: [
        OrderStatus.PARTIALLY_SHIPPED,
        OrderStatus.RELEASED_FOR_SHIPMENT,
      ],
    })
    return resp
  }

  // api call
  const { data, isFetching, isError } = useQuery({
    queryKey: ['BREAKDOWN_SUMMARY'],
    queryFn: getBreakdownSummaryData,
  })

  const getFormattedData = useMemo(() => {
    if (!data) return []

    const toolTipData = {
      UNSHIPPED: '',
      UNSHIPPED_PAST_DUE: `Orders at risk: ${ordersAtRiskCount}`,
      RELEASED_FOR_SHIPMENT: '',
      PARTIALLY_SHIPPED: 'Fill Rate: ',
    }

    const sortOrder = Object.keys(toolTipData)

    const orderedData = data
      .map((ele: any) => ({
        ...ele,
        tooltip: toolTipData[ele.order_status as keyof typeof toolTipData],
      }))
      .sort(
        (a: any, b: any) =>
          sortOrder.indexOf(a.order_status) - sortOrder.indexOf(b.order_status),
      )

    return orderedData
  }, [data, ordersAtRiskCount])

  const getLoadingState = () => {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container justify="flex-start">
          {Array.from({ length: 4 }).map((_, index) => (
            <Grid.Item key={index} xs={3} className="hc-pl-none">
              <Placeholder.Rect height="60px" emphasized />
            </Grid.Item>
          ))}
        </Grid.Container>
      </Placeholder>
    )
  }

  return (
    <Grid.Container direction="column" justify="space-between">
      <Grid.Item>
        <Grid.Container direction="row" justify="space-between" align="center">
          <Grid.Item>
            <p className="hc-fs-xl">Breakdown Summary</p>
          </Grid.Item>
          {isExternalUser && (
            <Grid.Item>
              <CtaButtons />
            </Grid.Item>
          )}
        </Grid.Container>
      </Grid.Item>

      <Grid.Item>
        {isError ? (
          <p>There was an error fetching the data</p>
        ) : isFetching ? (
          getLoadingState()
        ) : (
          <SummaryCards data={getFormattedData} />
        )}
      </Grid.Item>
    </Grid.Container>
  )
}

export default BreakdownSummary
