import { FC } from 'react'
import { Grid, Heading } from '@enterprise-ui/canvas-ui-react'

import Filters from './Filters'

const LandingPageTopSection: FC = () => {
  return (
    <>
      <Heading size={4}>Review Target recommended values</Heading>
      <p>
        Please review recommendations within 72 hours and choose to either apply
        recommended changes or retain original values. If no action is taken,
        the recommended changes will be automatically applied.
      </p>
      <Grid.Container>
        <Grid.Item xs={8}>
          <Filters />
        </Grid.Item>
      </Grid.Container>
    </>
  )
}

export default LandingPageTopSection
