import { Divider, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import OrdersToBeShippedGraph from './OrdersToBeShippedGraph'
import ReportDownload from 'v2/components/feature/ReturnInsights/ReportDownload'
import { StyledCardForGraphContainer } from '../styles'

const OrdersToBeShipped = () => {
  return (
    <StyledCardForGraphContainer className="hc-pa-normal">
      <Grid.Container justify="space-between" align="top">
        <Grid.Item className="hc-pl-md hc-pr-md">
          <Heading className="hc-fs-md">Orders To Be Shipped</Heading>
          <p className="font-color-secondary hc-fs-xs">
            Count of order IDs with items crossing Requested Ship Date
          </p>
        </Grid.Item>
        <Grid.Item>
          <ReportDownload
            returnPerformanceReportTypes={['']}
            fileName="orders-to-be-shipped"
          />
        </Grid.Item>
      </Grid.Container>
      <Divider className="hc-mt-xs" />
      <Grid.Container justify="center">
        <Grid.Item xs={12}>
          <OrdersToBeShippedGraph />
        </Grid.Item>
      </Grid.Container>
    </StyledCardForGraphContainer>
  )
}

export default OrdersToBeShipped
