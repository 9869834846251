import axios from 'axios'
import startCase from 'lodash/fp/startCase'

import { EnhancedTableFieldType } from 'components/common/EnhancedTable'

import formatCurrency from 'services/formatCurrency'
import { getSmsProduct } from 'services/items'

import apiConfig from 'config/apiConfig'

import {
  BiReportingFilter,
  BiData,
  BiColumn,
  BiReportingTimePeriod,
} from 'types/BiReporting'
import { MarketplaceProduct } from 'types/Item'
import { TaxonomyValueRequest } from 'types/TaxonomyAssignment'

import { formatDate, DATE_PICKER_FORMAT } from './dateService'
import { getPageable, PagingParams } from './pageableHelper'

export type CardParams = {
  cardId: string
  sellerId: string
  filters?: BiReportingFilter[]
  timePeriod?: BiReportingTimePeriod
  resultForm?: {
    date: string
  }
}

export const fetchCardDataThruSecurityGateway = (
  cardId: string,
  sellerId: string,
  filters?: BiReportingFilter[],
  timePeriod?: BiReportingTimePeriod,
  resultForm?: {
    date: string
  },
): Promise<BiData> => {
  return axios
    .post(
      `${apiConfig.securityGateway}/sellers/${sellerId}/execute_cards?card_id=${cardId}`,
      {
        filters,
        time_period: timePeriod,
        result_form: resultForm,
      },
    )
    .then((result) => result.data)
}

export const fetchCardDataWithFormattedKeys = async (
  cardId: string,
  sellerId: string,
  filters?: BiReportingFilter[],
  timePeriod?: BiReportingTimePeriod,
  resultForm?: {
    date: string
  },
): Promise<any[]> => {
  const result = await fetchCardDataThruSecurityGateway(
    cardId,
    sellerId,
    filters,
    timePeriod,
    resultForm,
  )

  const keyNames = result.card_config.card_query_attribute.columns.reduce(
    (acc, curr) => {
      const refId = curr.ref_id
      const name = curr.column_display_name || curr.field_name
      if (!acc[refId]) {
        acc[refId] = name
      }

      return acc
    },
    {} as any,
  )

  const formattedData = result.query_results.map((item) => {
    const normalizedItem = {} as any

    Object.keys(item).forEach((key) => {
      normalizedItem[keyNames[key]] = item[key]
    })

    return normalizedItem
  })

  return formattedData
}

export const mapColumnsToTableFieldList = (
  columns: BiColumn[],
): EnhancedTableFieldType[] => {
  const fieldList = columns.map((column) => {
    const field: EnhancedTableFieldType = {
      key: column.ref_id,
      heading: startCase(column.column_display_name),
    }

    if (column.format_type === 'date') {
      field.formatCell = (data: any) => {
        if (column?.format_string) {
          return formatDate(data[column.ref_id], DATE_PICKER_FORMAT)
        }

        return data[column.ref_id]
      }
    }

    if (column.format_type === 'currency_USD') {
      field.formatCell = (data: any) => formatCurrency(data[column.ref_id])
    }

    if (
      column.format_type === 'number' &&
      column.format_precision !== undefined
    ) {
      field.formatCell = (data: any) => {
        return (data[column.ref_id] as Number).toFixed(
          parseInt(column.format_precision as string, 10),
        )
      }
    }

    if (
      column.format_type === 'percentage' &&
      column.format_precision !== undefined
    ) {
      field.formatCell = (data: any) =>
        `${(data[column.ref_id] as Number).toFixed(
          parseInt(column.format_precision as string, 10),
        )}%`
    }

    return field
  })

  return fieldList
}

export const buildItemTaxonomyValueRequestData = async ({
  sellerId,
  marketplaceProduct,
  title,
  name,
  description,
  reason,
  itemType,
  attribute,
  checkTcin,
  userEmail,
}: {
  sellerId: string
  marketplaceProduct: Nullable<MarketplaceProduct>
  title: string
  name: string
  description: string
  reason: string
  itemType: string
  attribute: string
  checkTcin?: boolean
  userEmail: string
}): Promise<TaxonomyValueRequest> => {
  let vendorTitle = title
  let vendorDescription = description
  let tcin
  if (
    checkTcin &&
    marketplaceProduct &&
    typeof marketplaceProduct !== 'string' &&
    marketplaceProduct?.tcin
  ) {
    tcin = marketplaceProduct.tcin
    const res = await getSmsProduct({
      sellerId,
      productId: marketplaceProduct.product_id,
      params: { expand: 'fields' },
    })

    vendorTitle =
      res.fields?.find((field) => field.name === 'descriptions.title')?.value ??
      'Title not found'
    vendorDescription =
      res.fields?.find(
        (field) => field.name === 'descriptions.long_description',
      )?.value ?? 'Description not found'
  }

  return {
    created_by: userEmail,
    comment: reason,
    attribute_id: attribute,
    item_type_id: itemType,
    requested_value: name,
    request_source: 'MARKETPLACE',
    tcin_info: [
      {
        ...(tcin ? { tcin } : {}),
        title: vendorTitle,
        vendor_description: vendorDescription,
      },
    ],
  }
}

export const createTaxonomyAttributeAndValue = async (
  sellerId: string,
  data: TaxonomyValueRequest,
) => {
  return axios.post(
    `${apiConfig.securityGateway}/sellers/${sellerId}/value_requests`,
    data,
  )
}

export function getSellerRequestedValues(
  sellerId: string,
  pagingParams: PagingParams,
) {
  const pageable = getPageable(pagingParams)

  return axios
    .get(`${apiConfig.securityGateway}/sellers/${sellerId}/value_requests`, {
      params: { ...pageable, request_source: 'MARKETPLACE' },
    })
    .then((response) => ({
      data: response.data,
      total: parseInt(response.headers['x-total-count'] ?? '0', 10),
    }))
}

export function getSellerRequestedValueDetail(
  sellerId: string,
  requestId: string,
) {
  return axios
    .get(
      `${apiConfig.securityGateway}/sellers/${sellerId}/value_requests/${requestId}`,
    )
    .then((response) => response.data)
}
