import { Grid } from '@enterprise-ui/canvas-ui-react'
import HeaderTitle from 'components/common/HeaderTitle'
import { UnshippedPastDue } from 'v2/components/feature/OrdersDashboard'
import BreakdownSummary from 'v2/components/feature/OrdersDashboard/BreakdownSummary'
import SummaryCards, {
  SummaryInterface,
} from 'v2/components/feature/OrdersDashboard/BreakdownSummary/SummaryCards'
import Filter from 'v2/components/feature/OrdersDashboard/Filter'
import Header from 'v2/components/feature/OrdersDashboard/Header'
import OrderDetails from 'v2/components/feature/OrdersDashboard/OrderDetails'
import OrdersToBeShipped from 'v2/components/feature/OrdersDashboard/OrdersToBeShipped'

// TODO: remove dummy data after api integration
const dummyCountData: SummaryInterface[] = [
  {
    order_status: 'CANCELED',
    count: 382,
    tooltip: `Count of order IDs with at least one Return`,
  },
  {
    order_status: 'SHIPPED',
    count: 310,
    tooltip: `Count of order IDs with at least one cancellation`,
  },
]

const OrdersDashboard = () => {
  return (
    <>
      <HeaderTitle title="Orders" />
      <Header />
      <Grid.Item className="hc-mb-xl">
        <BreakdownSummary />
      </Grid.Item>
      <Grid.Container className="hc-mb-xl">
        <Grid.Item xs={6}>
          <UnshippedPastDue />
        </Grid.Item>
        <Grid.Item xs={6}>
          <OrdersToBeShipped />
        </Grid.Item>
      </Grid.Container>
      <Grid.Item xs={12} className="hc-mb-xl">
        <Filter />
      </Grid.Item>
      <SummaryCards data={dummyCountData} />
      <OrderDetails />
    </>
  )
}

export default OrdersDashboard
