import { Grid, Input } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { StyledDatePicker } from '../../ReturnInsights/styles'
import { orderStatusLabels } from 'services/enumerations'
import { OrderStatus } from 'types/Orders'
import { getEnumerationValues } from 'store/selectors'
import { EnumerationName } from 'types/Enumeration'
import { useSelector } from 'react-redux'
import StoreState from 'types/state'
import { StyledCard } from '../styles'
import { useOrdersDashboardStore } from 'v2/store'
import { useMemo } from 'react'

const Filter = () => {
  const {
    deliverByDate,
    orderPlacedDate,
    orderStatuses,
    requestShipDate,
    isOrdersAtRiskChecked,
    updateOrderStatuses,
    updateDeliverByDate,
    updateOrderPlacedDate,
    updateRequestShipDate,
    updateIsOrdersAtRiskChecked,
  } = useOrdersDashboardStore()

  const statuses = useSelector((state: StoreState) =>
    getEnumerationValues(state, EnumerationName.ORDER_STATUS),
  )

  const orderStatusesOptions = useMemo(() => {
    return statuses
      .map((stat) => ({
        id: stat,
        label: orderStatusLabels[stat as keyof typeof OrderStatus],
        value: stat,
      }))
      .filter(
        (status) =>
          status.value !== OrderStatus.PENDING &&
          status.value !== OrderStatus.REFUNDED,
      )
  }, [statuses])

  return (
    <StyledCard className="hc-bg-grey07 hc-ph-normal">
      <Grid.Container direction="column" justify="space-between">
        <Grid.Item>
          <Grid.Container align="top" justify="space-between">
            <Grid.Item>
              <p className="hc-fs-md">Showing Details For :</p>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item>
          <Grid.Container align="baseline" justify="space-between">
            <Grid.Item xs={3}>
              <Autocomplete
                multiselect
                label="Order Status"
                value={orderStatuses}
                placeholder="Select Order Status"
                options={orderStatusesOptions}
                onUpdate={(_, value) => updateOrderStatuses(value)}
              />
            </Grid.Item>
            <Grid.Item xs={3}>
              <StyledDatePicker
                id="orders-dashboard-date-picker"
                type="gregorian-range"
                label="Order Placed Date"
                placeholder="Select Date"
                onUpdate={(_: string, value: any) =>
                  updateOrderPlacedDate(value)
                }
                value={orderPlacedDate}
                noValidate
              />
            </Grid.Item>
            <Grid.Item xs={3}>
              <StyledDatePicker
                id="orders-dashboard-date-picker"
                type="gregorian-range"
                label="Deliver By"
                placeholder="Select Date"
                onUpdate={(_: string, value: any) => updateDeliverByDate(value)}
                value={deliverByDate}
                noValidate
              />
            </Grid.Item>
            <Grid.Item xs={3}>
              <StyledDatePicker
                id="orders-dashboard-date-picker"
                type="gregorian-range"
                label="Request Ship Date"
                placeholder="Select Date"
                onUpdate={(_: string, value: any) =>
                  updateRequestShipDate(value)
                }
                noValidate
                value={requestShipDate}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item>
          <Input.Checkbox
            className="hc-fs-xs"
            id="demo_00"
            label="Only show Orders at Risk"
            checked={isOrdersAtRiskChecked}
            onChange={(event: any) => {
              updateIsOrdersAtRiskChecked(event.target.checked)
            }}
          />
        </Grid.Item>
      </Grid.Container>
    </StyledCard>
  )
}

export default Filter
