import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { saveFile } from 'services/files'
import { downloadReport } from 'services/reports'

import { success as successColor, warning, white } from 'config/themeConfig'

import { Report } from 'types/Report'

import { CancelReportParams } from './useReports'
import { Box } from '@mui/material'

const StyledButtonProgress = styled(CircularProgress)({
  color: 'primary',
  position: 'absolute',
})

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'hasError' && prop !== 'hasSuccess',
})<{ hasError: boolean; hasSuccess: boolean }>((props) => ({
  color: props.hasError || props.hasSuccess ? white.main : '',
  backgroundColor: props.hasError
    ? warning.main
    : props.hasSuccess
      ? successColor.main
      : '',
  '&:hover': {
    color: props.hasError || props.hasSuccess ? white.main : '',
    backgroundColor: props.hasError
      ? warning.main
      : props.hasSuccess
        ? successColor.main
        : '',
  },
}))

interface Props {
  reportActions: {
    cancel: (params: CancelReportParams) => () => Promise<void>
    generate: () => void
  }
  reportStatuses: {
    hasError: boolean
    isGettingReports: boolean
    success: boolean
  }
  generateText?: string
  inProgressReport?: Report
  hasTable?: boolean
  isAuthorized: boolean
  sellerId?: string
  templateLink?: string
  templateName?: string
}

export const ReportCardActions = ({
  reportActions: { cancel, generate },
  reportStatuses: { success, hasError },
  generateText,
  inProgressReport,
  hasTable,
  isAuthorized,
  sellerId,
  templateLink,
  templateName,
}: Props) => {
  let reportButtonText = generateText ?? 'generate a report'
  if (inProgressReport) {
    reportButtonText = 'generating'
  } else {
    if (success && !hasError) {
      reportButtonText = 'generation complete'
    } else if (!success && hasError) {
      reportButtonText = 'generation error'
    }
  }

  const handleDownload = async (link: string, name: string) => {
    const data = await downloadReport(link)
    saveFile({ data, name })
  }

  return (
    <Box
      data-testid="report-card-actions"
      sx={{ margin: 1, position: 'relative' }}
    >
      {templateLink && templateName && (
        <Button
          onClick={() => handleDownload(templateLink, templateName)}
          color="primary"
          sx={{ margin: 2 }}
        >
          download template
        </Button>
      )}
      <StyledButton
        data-testid="generate-button"
        hasError={hasError}
        hasSuccess={success}
        variant="outlined"
        color="primary"
        disabled={!isAuthorized || (!!inProgressReport && !hasTable)}
        onClick={generate}
      >
        {reportButtonText}
        {!!inProgressReport && !hasTable && (
          <StyledButtonProgress
            data-testid="report-card-generating-progress"
            size={20}
            title="Generating Report"
          />
        )}
      </StyledButton>
      {inProgressReport && !hasTable && (
        <Button
          color="primary"
          onClick={() =>
            cancel({
              sellerId,
              reportId: inProgressReport.id,
            })
          }
          data-testid="cancel-button"
          sx={{ margin: 2 }}
        >
          Cancel
        </Button>
      )}
    </Box>
  )
}

export default ReportCardActions
