import { FC } from 'react'

import HeaderTitle from 'components/common/HeaderTitle'
import LandingPage from './LandingPage'

const TgidReview: FC = () => {
  return (
    <>
      <HeaderTitle title="TGID REVIEW"></HeaderTitle>
      <LandingPage />
    </>
  )
}

export default TgidReview
