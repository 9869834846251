import { useEffect, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  TooltipProps,
} from 'recharts'
import { useQuery } from '@tanstack/react-query'
import { Grid, Placeholder } from '@enterprise-ui/canvas-ui-react'
import { roundToTwoDecimals } from 'v2/utils/helper'
import { sellerExecuteCardQuery } from 'v2/query'
import { useReturnInsightsStore } from 'v2/store'
import { GREEN_FIELD_CARD_ID } from 'v2/constant/greenFieldCardID'

interface CustomTooltipProps extends TooltipProps<number, string> {}

export const PartnerReturnRateGraph = () => {
  const {
    divisionId,
    categoryId,
    interval,
    selectedVmmId,
    partnerReturnRateItemTypeIds,
  } = useReturnInsightsStore()
  const [graphData, setGraphData] = useState()

  const { data, isError, isFetching } = useQuery(
    [
      'PARTNER_LEVEL_RETURN_RATE',
      {
        divisionId,
        categoryId,
        partnerReturnRateItemTypeIds,
        interval,
        selectedVmmId,
      },
    ],
    () =>
      sellerExecuteCardQuery({
        divisionId,
        categoryId,
        interval,
        sellerId: '',
        cardId: GREEN_FIELD_CARD_ID.PARTNER_LEVEL_RETURN_RATE,
        vmmId: selectedVmmId,
        itemTypeIds: partnerReturnRateItemTypeIds,
      }),
  )

  useEffect(() => {
    if (data) {
      const transformedData = data.map((item: any) => ({
        ...item,
        y_axis: roundToTwoDecimals(item.y_axis),
        returnRate: item?.return_rate,
      }))

      setGraphData(transformedData)
    }
  }, [data])

  const CustomTooltip: React.FC<CustomTooltipProps> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload?.length) {
      const returnGmv = roundToTwoDecimals(payload?.[0]?.value ?? 0)
      const returnRate = roundToTwoDecimals(payload?.[0]?.payload?.returnRate)
      return (
        <div className="hc-bg-contrast-weak hc-pa-md">
          <p className="hc-ma-none">{`${label}`}</p>
          <p className="hc-ma-none">Return GMV: ${returnGmv}</p>
          <p className="hc-ma-none">
            Return Rate: {returnRate ? `${returnRate}%` : 'NA'}
          </p>
        </div>
      )
    }

    return null
  }

  if (isFetching) {
    return (
      <Placeholder className="hc-pa-normal">
        <Grid.Container justify="space-between" spacing="dense">
          <Grid.Item xs={12}>
            <Placeholder.Rect height="220px" />
          </Grid.Item>
        </Grid.Container>
      </Placeholder>
    )
  }

  if (isError) {
    return (
      <div className="hc-pa-expanded hc-ta-center">
        <p>There was an error fetching the data</p>
      </div>
    )
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        layout="vertical"
        data={graphData}
        margin={{ top: 20, right: 10, left: 0, bottom: 25 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="y_axis" angle={30} dy={14} type="number" />
        <YAxis
          dataKey="x_axis"
          type="category"
          tick={{ fontSize: 11 }}
          width={140}
        />

        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey="y_axis"
          fill="var(--canvas-font-color--interactive)"
          barSize={25}
          radius={[0, 10, 10, 0]}
        >
          <LabelList
            className="hc-fs-min"
            dataKey="y_axis"
            position="insideRight"
            fill="#fff"
            formatter={(value: any) => `$${value}`}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default PartnerReturnRateGraph
