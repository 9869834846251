import axios from 'axios'
import apiConfig from 'config/apiConfig'
import { buildURLQueryParams } from 'v2/utils/url'

/**
 * The API gets the unshipped orders data by filter age
 *
 * @param {{
 *   date: string
 *   sellerId: string
 * }} {
 *   date,
 *   sellerId,
 * }
 */
export const getUnshippedOrdersAge = async ({
  date,
  sellerId,
}: {
  date: string
  sellerId?: string
}) => {
  const params = buildURLQueryParams({
    date: date,
    seller_id: sellerId,
  })

  const url = `${apiConfig.sellerOrders}/unshipped_orders_age?${params}`
  const response = await axios.get(url)
  return response?.data ?? []
}
